import PropTypes from 'prop-types';
// @mui
import Scrollbar from 'src/components/scrollbar';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Typography, Unstable_Grid2 as Grid, Stack, IconButton } from '@mui/material';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import { EcommerceCartList, EcommerceCartSummary } from '../cart';
import EmptyContent from '../../../pages/EmptyContent';
// assets
import EmptyContentImage from '../../../assets/illustrations/illustration_empty_content.svg';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';

// ----------------------------------------------------------------------

export default function EcommerceCartView({
  summary,
  handleRemark,
  cartProduct,
  cartListing,
  handleNext,
  handleCheckPromo,
  handlePromoCode,
  promoCode,
  validPromoData,
  orderID,
  setHotelInfoDialog,
  handleVoucherCode,
  checkPromoData,
  parsedData,
}) {
  const navigate = useNavigate();  
  const { user } = useAuthContext();  
  
  const userDiscount = user?.AgentInd === 1 ? user?.AgentDiscount || 0 : 0
  return cartProduct.length > 0 ? (
    <Grid container spacing={{ xs: 5, md: 8 }}>
      <Grid xs={12} md={8}>
        <Scrollbar>
          {cartProduct.map((merchant, index) => (
            <Stack key={merchant.MerchantID ?? index}>
              <EcommerceCartList
                orderID={orderID}
                handleRemark={handleRemark}
                products={merchant.cartList}
                merchantData={merchant}
                setHotelInfoDialog={setHotelInfoDialog}
                handleVoucherCode={handleVoucherCode}
                checkPromoData={checkPromoData}
                parsedData={parsedData}
              />
            </Stack>
          ))}
        </Scrollbar>
      </Grid>
      <Grid xs={12} md={4}>
        <EcommerceCartSummary
          tax={summary.tax === 0 ? 0 : summary.tax}
          total={summary.subtotal + summary.shipping - summary.discount + summary.tax - ((userDiscount )/100 * summary.subtotal)}
          subtotal={summary.subtotal === 0 ? 0 : summary.subtotal}
          shipping={summary.shipping === 0 ? 0 : summary.shipping}
          discount={(summary.discount === 0 ? 0 : summary.discount ) +  ((userDiscount)/100 * summary.subtotal)}
          handleNext={handleNext}
          promoCode={promoCode}
          handleCheckPromo={handleCheckPromo}
          handlePromoCode={handlePromoCode}
          validPromoData={validPromoData}
          cartListing={cartListing}
          orderID={orderID}
        />
      </Grid>
    </Grid>
  ) : (
    <Stack direction="row" alignItems="center" textAlign="center" justifyContent="space-around">
      <EmptyContent
        title="Selected cart is empty"
        description="Look like you did't select items in your shopping cart."
        // img='../../../assets/illustrations/illustration_empty_content.svg'
        img={EmptyContentImage}
      />
    </Stack>
  );
}

EcommerceCartView.propTypes = {
  cartProduct: PropTypes.array,
  summary: PropTypes.object,
  handleNext: PropTypes.func,
  handlePromoCode: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  promoCode: PropTypes.string,
  validPromoData: PropTypes.object,
  cartListing: PropTypes.array,
  orderID: PropTypes.string,
  setHotelInfoDialog: PropTypes.func,
  handleVoucherCode: PropTypes.func,
  checkPromoData: PropTypes.func,
  handleRemark: PropTypes.func,
  parsedData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
};
