import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import {
  Box,
  Button,
  Card,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
// routes
// _mock
// components
import FormProvider from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
//
import { EcommerceCheckoutOrderSummary, EcommerceCheckoutPaymentMethod } from '../checkout';

import STRIPE from '../../../assets/stripe-logo.png';
import TOYYIBPAY from '../../../assets/toyyibPay.png';
// ----------------------------------------------------------------------

const SHIPPING_OPTIONS = [
  {
    label: 'Self Pick Up',
    value: 'free',
    description: 'Self Collection At Store',
    price: 0,
  },
  {
    label: 'Standard',
    value: 'standard',
    description: '5-7 Days delivery',
    price: 10,
  },
];

const PAYMENT_OPTIONS = [
  {
    value: '1',
    label: 'Online Banking',
    description: 'You will be redirected to toyyibPay website to complete your purchase securely.',
    icons: [TOYYIBPAY],
  },
  {
    value: '2',
    label: 'Stripe',
    description: 'Pay with Stripe using your credit card',
    icons: [STRIPE],
  },
];

// ----------------------------------------------------------------------

export default function EcommerceCheckoutPaymentView({
  // card_information, fpx_information,
  onSubmitOrder,
  summary,
  cartProduct,
  cartListing,
  handleNext,
  handlePrevious,
  address,
  //  handleBanking,
  methodSelection,
  handleSelection,
  orderID,
  // bankSelected,
  // bankOptions,
  handleCheckPromo,
  handlePromoCode,
  promoCode,
  validPromoData,
  cartType,
  setHotelInfoDialog,
  IsAvailablePromo,
}) {
  const EcommerceCheckoutSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(EcommerceCheckoutSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      // reset();
      // navigate(paths.eCommerce.orderCompleted);
    } catch (error) {
      console.error(error);
    }
  };

  const getShippingLabel = () => {
    let label = 3;
    switch (cartType) {
      case 'eventStep':
        label = 2;
        break;

      case 'hotelStep':
        label = 4;
        break;

      case 'combinationStep':
        label = 5;
        break;

      default:
        break;
    }
    return label;
  };

  const handlePaymentMethodSelection = (value) => {
    handleSelection(value);
  };

  return (
    <>
      <Typography variant="h3" sx={{ mb: 5 }}>
        Checkout
      </Typography>
      <Button
        onClick={handlePrevious}
        color="inherit"
        startIcon={<Iconify icon="carbon:chevron-left" />}
        sx={{ mb: 3 }}
      >
        {cartType === 'productStep' ? 'Back To Delivery Address' : 'Back To Billing Information'}
      </Button>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={{ xs: 5, md: 8 }}>
          <Grid xs={12} md={8}>
            <Stack spacing={5} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
              {cartType !== 'eventStep' && cartType !== 'hotelStep' && (
                <>
                  <StepLabel title="Shipping Method" step={String(getShippingLabel())} />
                  <Grid container>
                    {SHIPPING_OPTIONS.map((item, index) => (
                      <Grid
                        md={4}
                        xs={12}
                        sm={6}
                        spacing={1}
                        pr={0.5}
                        key={index}
                        sx={{ width: '100%' }}
                      >
                        <ShippingMethod
                          shippingMethod={item}
                          selectedAddressID={address?.UserAddressBookID}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              <>
                <StepLabel title="Payment Method" step={String(getShippingLabel() + 1)} />

                <EcommerceCheckoutPaymentMethod
                  options={PAYMENT_OPTIONS}
                  handleSelection={handlePaymentMethodSelection}
                  // handleBanking={handleBanking}
                  // bankSelected={bankSelected}
                  // bankOptions={bankOptions}
                  selection={methodSelection}
                />
              </>
            </Stack>
          </Grid>

          <Grid xs={12} md={4}>
            <EcommerceCheckoutOrderSummary
              tax={summary.tax === 0 ? 0 : summary.tax}
              total={summary.subtotal + summary.shipping - summary.discount + summary.tax}
              subtotal={summary.subtotal === 0 ? 0 : summary.subtotal}
              shipping={summary.shipping === 0 ? 0 : summary.shipping}
              discount={summary.discount === 0 ? 0 : summary.discount}
              handleNext={handleNext}
              // bankSelected={bankSelected}
              selection={methodSelection}
              products={cartProduct}
              cartListing={cartListing}
              loading={isSubmitting}
              pageType="payment"
              // card_information={card_information}
              // fpx_information={fpx_information}
              onSubmitOrder={onSubmitOrder}
              cartType={cartType}
              promoCode={promoCode}
              handleCheckPromo={handleCheckPromo}
              handlePromoCode={handlePromoCode}
              validPromoData={validPromoData}
              orderID={orderID}
              setHotelInfoDialog={setHotelInfoDialog}
              isPromoApplied={IsAvailablePromo}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}

EcommerceCheckoutPaymentView.propTypes = {
  handlePrevious: PropTypes.func,
  address: PropTypes.object,
  // bankOptions: PropTypes.array,
  // bankSelected: PropTypes.string,
  // handleBanking: PropTypes.func,
  handleSelection: PropTypes.func,
  methodSelection: PropTypes.number,
  summary: PropTypes.object,
  cartProduct: PropTypes.array,
  handleNext: PropTypes.func,
  // card_information: PropTypes.object,
  // fpx_information: PropTypes.object,
  onSubmitOrder: PropTypes.func,
  handlePromoCode: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  promoCode: PropTypes.string,
  validPromoData: PropTypes.object,
  cartListing: PropTypes.array,
  orderID: PropTypes.string,
  cartType: PropTypes.string,
  setHotelInfoDialog: PropTypes.func,
  IsAvailablePromo: PropTypes.number,
};

ShippingMethod.propTypes = {
  shippingMethod: PropTypes.object,
  selectedAddressID: PropTypes.number,
};

function ShippingMethod({ shippingMethod, selectedAddressID }) {
  const { label, value, description, price } = shippingMethod;

  const checkedRadio = (data) => {
    let isChecked = false;

    if (value === 'free' && selectedAddressID !== '' && selectedAddressID === 0) isChecked = true;

    if (value === 'standard' && selectedAddressID !== '' && selectedAddressID !== 0)
      isChecked = true;

    return isChecked;
  };

  return (
    <Card
      sx={{
        p: 1,
        mb: 2,
        textAlign: 'left',
      }}
    >
      <Stack
        spacing={2}
        alignItems={{
          md: 'flex-end',
        }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
      >
        <Stack flexGrow={1} spacing={1}>
          <Stack direction="row" alignItems="center">
            {checkedRadio(value) ? (
              <IconButton>
                <Iconify icon="ic:round-check-circle-outline" color="primary.main" />
              </IconButton>
            ) : (
              <IconButton>
                <Iconify icon="ic:round-radio-button-unchecked" />
              </IconButton>
            )}

            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
              {label}
            </Typography>
          </Stack>

          <Typography variant="body2" pl={1} sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

function StepLabel({ step, title }) {
  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 3, typography: 'h6' }}>
      <Box
        sx={{
          mr: 1.5,
          width: 28,
          height: 28,
          flexShrink: 0,
          display: 'flex',
          typography: 'h6',
          borderRadius: '50%',
          alignItems: 'center',
          bgcolor: 'primary.main',
          justifyContent: 'center',
          color: 'primary.contrastText',
        }}
      >
        {step}
      </Box>
      {title}
    </Stack>
  );
}

StepLabel.propTypes = {
  step: PropTypes.string,
  title: PropTypes.string,
};
