import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
//
import {
  // Travel
  TravelBlogPage,
  TravelPostPage,
  TravelTourPage,
  TravelAboutPage,
  TravelToursPage,
  TravelContactPage,
  TravelLandingPage,
  TravelCheckoutPage,
  TravelOrderCompletedPage,

  // Auth
  LoginCoverPage,
  VerifyCodePage,
  RegisterCoverPage,
  ResetPasswordPage,
  LoginBackgroundPage,
  LoginIllustrationPage,
  RegisterBackgroundPage,
  RegisterIllustrationPage,


  EcommerceCartPage,
  EcommerceCheckoutPage,
  EcommerceOrderCompletedPage,
  EcommerceWishlistPage,
  EcommerceProductPage,
  EcommerceAccountPersonalPage,
  EcommerceAccountOrdersPage,
  EcommerceAccountOrdersDetailPage,
  EcommerceAccountOrdersDetailPageExt,


  // Common
  Page404,
  Page500,
  HomePage,
  PaymentPage,
  SupportPage,
  RulePage,
  TermPage,
  TreeHouseTerm,
  Pricing01Page,
  Pricing02Page,
  ComingSoonPage,
  MaintenancePage,
  FAQs,
  PrivacyPolicy,
  Disclaimer,
  AboutPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Non layout
    {
      path: 'auth',
      children: [
        { path: 'login', element: <LoginCoverPage /> },
        { path: 'register', element: <RegisterCoverPage /> },
      ],
    },
    // Main layout
    {
      element: <MainLayout />,
      children: [
        { element: <TravelLandingPage />, index: true },
        { path: 'support', element: <SupportPage /> },
        { path: 'ruleandregulation', element: <RulePage /> },
        { path: 'termsandcondition', element: <TermPage /> },
        { path: 'treehousetermsandcondition', element: <TreeHouseTerm /> },
        { path: 'contactus', element: <TravelContactPage /> },
        { path: 'faqs', element: <FAQs /> },
        { path: 'privacy', element: <PrivacyPolicy /> },
        { path: 'disclaimer', element: <Disclaimer /> },
        { path: 'about', element: <AboutPage /> },

        { path: 'orderDetail/:id', element: <EcommerceAccountOrdersDetailPageExt /> },
        // Travel
        {
          path: 'travel',
          children: [
            { path: 'landing', element: <TravelLandingPage /> },
            { path: 'tours', element: <TravelToursPage /> },
            { path: 'tour', element: <TravelTourPage /> },
            { path: 'checkout', element: <TravelCheckoutPage /> },
            { path: 'order-completed', element: <TravelOrderCompletedPage /> },
            { path: 'posts', element: <TravelBlogPage /> },
            { path: 'post', element: <TravelPostPage /> },
            { path: 'about', element: <TravelAboutPage /> },
            { path: 'contact', element: <TravelContactPage /> },
          ],
        },


        // E-commerce
        {
          children: [
            // { element: <EcommerceLandingPage />, index: true },
            // { path: 'products/:keyword/:name', element: <EcommerceProductsPage /> },
            { path: 'product/:name', element: <EcommerceProductPage /> },
            { path: 'allProduct', element: <TravelToursPage /> },
            // { path: 'merchant/chat', element: <EcommerceMerchantChat /> },
            // { path: 'merchantViewProducts', element: <MerchantProductView /> },
            { path: 'cart', element: <EcommerceCartPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
            { path: 'ShopPageReceipt/:transactionNo/:billcode', element: <EcommerceOrderCompletedPage /> },
            { path: 'wishlist', element: <EcommerceWishlistPage /> },
            // { path: 'compare', element: <EcommerceComparePage /> },
            // { path: 'merchantProduct/:merchantID', element: <EcommerceMerchantProductPage /> },
            {
              path: 'account',
              children: [
                { path: 'personal', element: <EcommerceAccountPersonalPage /> },
                // { path: 'address', element: <EcommerceAccountAddressPage /> },
                // { path: 'wishlist', element: <EcommerceAccountWishlistPage /> },
                // { path: 'vouchers', element: <EcommerceAccountVouchersPage /> },
                { path: 'orders', element: <EcommerceAccountOrdersPage /> },
                { path: 'orderDetail/:id', element: <EcommerceAccountOrdersDetailPage /> },
                // { path: 'payment', element: <EcommerceAccountPaymentPage /> },
              ],
            },
            // { path: 'youthEmpower', element: <EcommerceYouthEmpowerPage /> },
            // { path: 'emporiaapp/:type/:id', element: <EcommerceAppProceedCart /> },
          ],
        },


        {
          path: 'hotel',
          children: [
            { path: 'landing', element: <TravelLandingPage /> },
            { path: 'lists', element: <TravelToursPage /> },
            { path: 'list/:id', element: <TravelTourPage /> },
            { path: 'checkout', element: <TravelCheckoutPage /> },
            { path: 'order-completed', element: <TravelOrderCompletedPage /> },
            { path: 'posts', element: <TravelBlogPage /> },
            { path: 'post', element: <TravelPostPage /> },
            { path: 'about', element: <TravelAboutPage /> },
            { path: 'contact', element: <TravelContactPage /> },
          ],
        },

      ],
    },
    // Simple layout
    {
      element: <SimpleLayout />,
      children: [
        { path: 'payment', element: <PaymentPage /> },
        { path: 'pricing-01', element: <Pricing01Page /> },
        { path: 'pricing-02', element: <Pricing02Page /> },
        {
          path: 'auth',
          children: [
            { path: 'login-background', element: <LoginBackgroundPage /> },
            { path: 'login-illustration', element: <LoginIllustrationPage /> },
            { path: 'register-background', element: <RegisterBackgroundPage /> },
            { path: 'register-illustration', element: <RegisterIllustrationPage /> },
          ],
        },
      ],
    },
    // Compact layout
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: 'reset-code', element: <ResetPasswordPage /> },
        { path: 'verify-code', element: <VerifyCodePage /> },
        {
          path: 'auth',
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'verify-code', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
