import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
// @mui
import { Box, Button, Typography, Stack, Container } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { MotionContainer, varBounce } from 'src/components/animate';
//
import { EcommerceHeader } from '../layout';
import { useDispatch, useSelector } from '../../../redux/store';
import LoadingScreen from '../../../components/loading-screen';
import {
  viewOrderStatusByUUID,
  clearOrderData,
  updateToyyiPayStatus,
  updateBankResponseData,
} from '../../../redux/slices/order';

// ----------------------------------------------------------------------

export default function EcommerceOrderCompletedView({ billcode, transactionNo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderStatusByID } = useSelector((data) => data.order);
  const [paymentData, setPaymentData] = useState({
    isDataBind: false,
    data: [],
    isErrorPage: false,
  });

  const [orderData, setOrderData] = useState([]);
  const [isOrderSet, setOrder] = useState(false);

  useEffect(() => {
    if (billcode && transactionNo) {
      checkToyyibPay();
      dispatch(viewOrderStatusByUUID(transactionNo, 3));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billcode, transactionNo]);

  useEffect(() => {
    if (orderStatusByID) {
      setOrder(true);
      setOrderData(orderStatusByID);
      dispatch(clearOrderData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusByID]);

  const handleNavigation = () => {
    window.location.href = 'booklahapp://lightBuyEventTicketConfirmPaymentScreen';
  };

  const getOrderData = (propData, type) => {
    let returnData = '';

    if (type === 'OrderNumber') {
      propData.map((x, index) => {
        returnData += x.OrderID;
        if (index < propData.length - 1) returnData += ',';
        return {};
      });
    } else {
      returnData = parseFloat(
        propData.reduce((subtotal, item) => subtotal + item.OrderTotalAmount, 0)
      ).toFixed(2);
    }

    return returnData;
  };

  const checkToyyibPay = () => {
    const formdata = new FormData();
    formdata.append('billCode', billcode);

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch('https://dev.toyyibpay.com/index.php/api/getBillTransactions', requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result) {
          if (result.replace(/\s/g, '') !== 'No data found!') {
            const returnData = JSON.parse(result)[0];

            dispatch(
              updateToyyiPayStatus({
                ...returnData,
                settlementReferenceNo:
                  returnData.SettlementReferenceNo === '' ? '-' : returnData.SettlementReferenceNo,
                billcode,
              })
            );

            setPaymentData({
              isDataBind: true,
              data: returnData,
              isErrorPage: false,
            });
          } else {
            setPaymentData({ isDataBind: true, data: [], isErrorPage: true });
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const notFoundPage = () => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>📂</Box>
      </m.div>
      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: '#3C4C24' }}>
          Order Transaction Not Found
        </Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Unfortunately transaction was found in our system. Please contact administrator for this
            issue
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>
      {/* {
        orderIDStatus[0].orderOrigin === 'web' ? */}
      <Button
        // component={RouterLink}
        onClick={() => navigate(paths.hotel.landing)}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        Continue Shopping
      </Button>{' '}
      {/*: <Button
            onClick={() => handleNavigation()}
            size="large"
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="carbon:chevron-left" />}
          >
            Continue Shopping
          </Button>
      } */}
    </>
  );

  const successPage = (propData) => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>🎉</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: 'green' }}>
          Your order is complete!
        </Typography>

        <Typography variant="h5">Order Number: {getOrderData(propData, 'OrderNumber')}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {getOrderData(propData, 'OrderTotal')}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Your payment had been processed successfully
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>
      {/* 
      {
        orderIDStatus[0].orderOrigin === 'web' ? */}
      <Button
        // component={RouterLink}
        onClick={() => navigate(paths.eCommerce.account.orderDetail(orderData[0].OrderID))}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
      {/* :
          <Button
            // component={RouterLink}
            onClick={() => handleNavigation()}
            size="large"
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="carbon:chevron-left" />}
          >
            Continue Shopping
          </Button>
      } */}
    </>
  );

  const pendingPage = (propData) => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>⏳</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3">Pending Approval</Typography>

        <Typography variant="h5">Order Number: {getOrderData(propData, 'OrderNumber')}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {getOrderData(propData, 'OrderTotal')}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Your request has been sent. Pending for authorizer Approval
            {propData?.OrderTotalAmount > 30000 &&
              '. Maximum Transaction Limit Exceeded RM30000.00'}
            {propData?.OrderTotalAmount < 1 &&
              '. Transaction Amount is Lower than the Minimum Limit RM1.00'}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Reject Reason : {paymentData.data[0].billpaymentSettlement}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>

      {/* {
        orderIDStatus[0].orderOrigin === 'web' ? */}
      <Button
        // component={RouterLink}
        onClick={() => navigate(paths.eCommerce.account.orderDetail(orderData[0].OrderID))}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
      {/* :
          <Button
            // component={RouterLink}
            onClick={() => handleNavigation()}
            size="large"
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="carbon:chevron-left" />}
          >
            Continue Shopping
          </Button>
      } */}
    </>
  );

  const errorPage = (propData) => (
    <>
      <m.div variants={varBounce().in}>
        <Box sx={{ fontSize: 128 }}>❌</Box>
      </m.div>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="h3" sx={{ color: 'red' }}>
          Transaction Failed
        </Typography>

        <Typography variant="h5">Order Number: {getOrderData(propData, 'OrderNumber')}</Typography>

        <Typography variant="h5">Transaction Id: {transactionNo}</Typography>

        <Typography variant="h5">
          Transaction Amount: RM {getOrderData(propData, 'OrderTotal')}
        </Typography>

        <Stack spacing={1} sx={{ my: 5, pt: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            Unfortunately payment was unsuccessful
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Page will be automatically redirected to the main page or click button below
          </Typography>
        </Stack>
      </Stack>

      {/* {
        orderIDStatus[0].orderOrigin === 'web' ? */}
      <Button
        // component={RouterLink}
        onClick={() => navigate(paths.hotel.landing)}
        size="large"
        color="inherit"
        variant="contained"
        startIcon={<Iconify icon="carbon:chevron-left" />}
      >
        View Order History
      </Button>
      {/* :
          <Button
            // component={RouterLink}
            onClick={() => handleNavigation()}
            size="large"
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="carbon:chevron-left" />}
          >
            Continue Shopping
          </Button>
      } */}
    </>
  );

  const handleReturnPage = (paymentStatus) => {
    let returnPage = '';

    switch (paymentStatus) {
      case '1':
        returnPage = successPage(orderData);
        break;
      case '2':
        returnPage = pendingPage(orderData);
        break;
      case '3':
        returnPage = errorPage(orderData);
        break;
      case '4':
        returnPage = pendingPage(orderData);
        break;

      default:
        returnPage = pendingPage(orderData);
        break;
    }

    return returnPage;
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        paymentData.isDataBind === true && isOrderSet ? (
          <Container
            component={MotionContainer}
            sx={{
              textAlign: 'center',
              pb: { xs: 10, md: 20 },
            }}
          >
            {paymentData.data && paymentData.data && orderData.length > 0
              ? handleReturnPage(paymentData.data.billpaymentStatus)
              : notFoundPage()}
          </Container>
        ) : (
          <LoadingScreen />
        )
      }
    </>
  );
}

EcommerceOrderCompletedView.propTypes = {
  transactionNo: PropTypes.string,
  billcode: PropTypes.string,
};
