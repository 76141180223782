import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Button, Grid, Stack, TextField } from '@mui/material';

// ----------------------------------------------------------------------

export default function QuantityControl({ name, value, onMinus, onAdd, mt, mb, textFieldWidth, noAbleroAdd, ...other }) {
  return (
    <Stack direction="row" alignItems="center" {...other}>
      <QuantityButton
        label="-"
        onAction={() => onMinus(value)}
        disabled={value === 0}
      />
      <TextField
        name={name}
        value={value}
        size="small"
        sx={{ input: { textAlign: "center", alignItems: "center", } }}
        InputProps={{
          inputProps: {
            style: {
              textAlign: 'center',
              alignItems: "center",
              marginTop: mt,
              marginBottom: mb,
              width: textFieldWidth,
            },
          },
        }}
      />
      <QuantityButton
        label="+"
        disabled={noAbleroAdd === true}
        onAction={() => onAdd(value)}
      />
    </Stack>
  );
}

QuantityControl.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  mb: PropTypes.number,
  mt: PropTypes.number,
  onMinus: PropTypes.func,
  onAdd: PropTypes.func,
  textFieldWidth: PropTypes.number,
  noAbleroAdd: PropTypes.bool,
};

// ----------------------------------------------------------------------

export function QuantityButton({ label, onAction, ...others }) {
  return (
    <Button
      onClick={onAction}
      sx={{
        minWidth: 32,
        width: 32,
        height: 32,
        boxShadow: 'none',
      }}
      {...others}
    >
      {label}
    </Button>
  )
}

QuantityButton.propTypes = {
  label: PropTypes.string,
  onAction: PropTypes.func
};
