// @mui
import { Stack, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function EcommerceProductDetailsSkeleton() {
  return (
    <>

      <Stack spacing={1} sx={{ mb: 2 }}>
        
      <Skeleton variant="rounded" height={20} width="20%" />

        <Skeleton variant="rounded" height={50} width="90%" />

        <Stack direction="row" alignItems="center" flexGrow={1} >
          <Skeleton variant="rounded" height={36} width="80%" pt={10}/>
        </Stack>

        <Stack direction="row" alignItems="center" flexGrow={1} >
          <Skeleton variant="rounded" height={36} width="70%" pt={10}/>
        </Stack>

        <Stack direction="row" alignItems="center" flexGrow={1} >
          <Skeleton variant="rounded" height={36} width="60%" pt={10}/>
        </Stack>
      </Stack>

      <Stack spacing={2} direction="row" pt={5}>
        <Skeleton variant="rounded" height={50} width="30%" />

        <Skeleton variant="rounded" height={50} width="30%" />

        <Skeleton variant="rounded" height={50} width="30%" />
      </Stack>


      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ md: 'center' }} pt={10}>
        <Skeleton variant="rounded" height={70} width="30%" />

        <Skeleton variant="rounded" height={70} width="30%" />

        <Skeleton variant="rounded" height={70} width="30%" />
      </Stack>

      <Stack  pt={3}>
        <Skeleton variant="rounded" height={50} width="20%" />
      </Stack>
    </>
  );
}
