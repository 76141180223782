import { useLocation, Link as RouterLink, useNavigate, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// @mui
import { alpha, styled } from '@mui/material/styles';
import Masonry from '@mui/lab/Masonry';
import {
  Link,
  Stack,
  Button,
  Divider,
  Container,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Unstable_Grid2 as Grid,
  Alert, Box,
  Fab
} from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// _mock
import { _socials } from 'src/_mock';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
//
import { paths } from 'src/routes/paths';
import { pageLinks, navConfig } from '../nav/config-navigation';
import ListDesktop from './ListDesktop';
import ListMobile from './ListMobile';
import { ServerConfiguration } from '../../../utils/serverConfig';
import FloatingFAQ from './floatingFAQ'
// ----------------------------------------------------------------------

const StyledAppStoreButton = styled(Button)(({ theme }) => ({
  flexShrink: 0,
  padding: '5px 12px',
  margin: theme.spacing(1),
  color: theme.palette.common.white,
  border: `solid 1px ${alpha(theme.palette.common.black, 0.24)}`,
  background: `linear-gradient(180deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
  '& .MuiButton-startIcon': {
    marginLeft: 0,
  },
}));

// ----------------------------------------------------------------------

export default function Footer() {
  const isMdUp = useResponsive('up', 'md');
  // const navigate = useNavigate();

  const url = ServerConfiguration.testingServerUrl;
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [isvalid, setIsValid] = useState(false);

  const { pathname } = useLocation();

  const mobileList = navConfig.find((i) => i.title === 'Pages')?.children || [];

  const desktopList = pageLinks.sort((listA, listB) => Number(listA.order) - Number(listB.order));

  const renderLists = isMdUp ? desktopList : mobileList;

  const isHome = pathname === '/';

  useEffect(() => {
    if (email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsValid(regex.test(email))
    }
  }, [email]);


  const handleSubscription = async () => {
    try {

      const subject = 'Subscribe to Abba Paradise Weekly Newsletter!'
      const message = `I&apos;m reaching out to express my interest in subscribing to Abba Paradise's weekly newsletter. I&apos;ve heard wonderful things about your serene retreat and would love to stay informed about the latest articles, updates, and offers.`
      const response = await fetch(
        `${url}User_AddUserFeedback?SUBJECT=${subject}&BODY=${message}&NAME=${email}&EMAIL=${email}`
      );
      const json = await response.json();
      const resp = JSON.parse(json)[0];
      if (resp && resp.ReturnVal === 1) {
        enqueueSnackbar('Thank you for your subscription', { variant: 'success' });
        setEmail("")
        setIsValid(false)
      } else {
        enqueueSnackbar(
          'There is an error to sent your subscription. Kindly contact administrator for assistant',
          { variant: 'error' }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (data) => {
    setEmail(data.target.value)
  }

  const simpleFooter = (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      <Logo single />

      <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
        © 2024. All rights reserved
      </Typography>
    </Container>
  );

  const footerLink = [
    { to: paths.term, title: "Abba Terms and Condition" },
    { to: paths.treehouseterm, title: "Tree House Terms and Condition" },
    { to: paths.rule, title: "Rules and Regulation" },
    { to: paths.privacy, title: "Privacy Policy" },
    { to: paths.disclaimer, title: "Disclaimer" },
    { to: paths.faqs, title: "FAQs" },
    { to: paths.about, title: "About Us" },
    { to: paths.contactus, title: "Contact Us" },
  ]


  const mainFooter = (
    <>
      <FloatingFAQ />
      <Divider />

      <Container
        sx={{
          overflow: 'hidden',
          py: { xs: 8, md: 10 },
        }}
      >
        <Grid container spacing={3} justifyContent={{ md: 'space-between' }}>
          <Grid xs={12} md={4}>
            <Stack spacing={{ xs: 3, md: 5 }}>
              <Stack alignItems="flex-start" spacing={3}>
                <Logo />
                <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'justify' }}>
                  Discover the serenity of Abba Paradise, where nature meets tranquility. Nestled
                  amidst breathtaking landscapes, our humble retreat offers an escape from the
                  hustle and bustle of city life. With cozy accommodations, captivating scenery, and
                  warm hospitality, Abba Paradise is your haven for relaxation and rejuvenation.
                  Explore the beauty of nature and create unforgettable memories at Abba Paradise.
                  {/* Thank you for the overwhelming response to the Abba Tree House!

                  Enjoy 10% off on your next tree house booking!*

                  *Exclusively for all new and previous guests who have made bookings for the Abba Tree House.
                  Kindly contact us and provide your previous invoice to enjoy this discount! */}
                </Typography>
              </Stack>

              {/* <Stack spacing={1} alignItems="flex-start">
                <Typography variant="h6">Community</Typography>
                <Link variant="body2" sx={{ color: 'text.primary' }}>
                  Documentation
                </Link>
                <Link variant="body2" sx={{ color: 'text.primary' }}>
                  Changelog
                </Link>
                <Link variant="body2" sx={{ color: 'text.primary' }}>
                  Contributing
                </Link>
              </Stack> */}

              {/* <Stack spacing={2}>
                <Typography variant="h6">Apps</Typography>
                <AppStoreButton />
              </Stack> */}
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            {/* {isMdUp ? (
              <Masonry columns={4} spacing={2} defaultColumns={4} defaultSpacing={2}>
                {renderLists.map((list) => (
                  <ListDesktop key={list.subheader} list={list} />
                ))}
              </Masonry>
            ) : (
              <Stack spacing={1.5}>
                {renderLists.map((list) => (
                  <ListMobile key={list.subheader} list={list} />
                ))}
              </Stack>
            )} */}

            <Stack spacing={2}>
              <Stack spacing={1}>
                <Typography variant="h6">Let’s stay in touch</Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  Subscribe to our newsletter to receive latest articles to your inbox weekly.
                </Typography>
              </Stack>
              {
                !isvalid && email !== '' && <Alert severity="error" sx={{ width: '100%' }}> Valid Email is Required </Alert>
              }
              <TextField
                fullWidth
                hiddenLabel
                placeholder="Email address"
                value={email}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button variant="contained" color="inherit" size="large" disabled={!isvalid} onClick={() => handleSubscription()}>
                        Subscribe
                      </Button>
                    </InputAdornment>
                  ),
                  sx: { pr: 0.5 },
                }}
              />
              <Stack spacing={2}>
                <Typography variant="h6">Social</Typography>
                <Stack direction="row" alignItems="center">
                  {_socials.map((social) => (
                    <IconButton key={social.value} color="primary" onClick={() => window.open(social.link)}>
                      <Iconify icon={social.icon} />
                    </IconButton>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Stack
          spacing={2.5}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{ py: 2, textAlign: 'center' }}
        >
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            © 2024. All rights reserved
          </Typography>

        </Stack>


        <Box
          gap={{ xs: 2, md: 1,}}
          display="grid"
          sx={{ py: 3 }}
          gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(4, 1fr)', xl: 'repeat(6, 1fr)' }}
        >
          {
            footerLink.map((data) =>

              <Link
                component={RouterLink}
                variant="caption"
                to={data.to}
                sx={{ color: 'text.secondary' }}
              >
                {data.title}
              </Link>
            )
          }
        </Box>
      </Container>
    </>
  );

  return <footer>{isHome ? mainFooter : mainFooter}</footer>;
}

// ----------------------------------------------------------------------

function AppStoreButton({ ...other }) {
  return (
    <Stack direction="row" flexWrap="wrap" {...other}>
      <StyledAppStoreButton startIcon={<Iconify icon="ri:apple-fill" width={28} />}>
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Download on the
          </Typography>

          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Apple Store
          </Typography>
        </Stack>
      </StyledAppStoreButton>

      <StyledAppStoreButton startIcon={<Iconify icon="logos:google-play-icon" width={28} />}>
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Download from
          </Typography>
          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Google Play
          </Typography>
        </Stack>
      </StyledAppStoreButton>
    </Stack>
  );
}
