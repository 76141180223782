import PropTypes from 'prop-types';
import moment from 'moment';
import { useState, useEffect } from 'react';
// utils
import { fCurrency } from 'src/utils/formatNumber';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// @mui
import {
  Stack,
  Button,
  Rating,
  Typography,
  TextField,
  Divider,
  Alert,
  Autocomplete,
} from '@mui/material';
// hooks
import Notification from 'src/components/Notification/Notification';
import useResponsive from 'src/hooks/useResponsive';
// routes
import { paths } from 'src/routes/paths';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
// import AddOnDialog from 'src/sections/_e-commerce/view/AddOnDialog'
import { QuantityControl } from 'src/components/hook-form';
import { checkAccessCategory } from 'src/utils/helpers';
//
import { ProductPricing, ProductCustomizeOptionPicker } from '../../components';
// auth
import { useAuthContext } from '../../../../auth/useAuthContext';
import {
  viewProductCart,
  addProductCart,
  updateProductCart,
  clearProductCartAction,
  viewProductWishlist,
  addProductWishlist,
  deleteProductWishlist,
  clearProductWishlistAction,
} from '../../../../redux/slices/product';
import { useDispatch, useSelector } from '../../../../redux/store';

// ----------------------------------------------------------------------

EcommerceProductDetailsInfo.propTypes = {
  caption: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  rating: PropTypes.number,
  review: PropTypes.number,
  variation: PropTypes.array,
  productId: PropTypes.number,
  promotion: PropTypes.array,
  product: PropTypes.object,
  ProductimageData: PropTypes.array,
  shopName: PropTypes.string,
  merchantID: PropTypes.number,
  isQuickview: PropTypes.string,
  numberofday: PropTypes.number,
};

export default function EcommerceProductDetailsInfo({
  name,
  price,
  rating,
  review,
  caption,
  variation,
  productId,
  promotion,
  product,
  ProductimageData,
  shopName,
  merchantID,
  isQuickview,
  numberofday,
}) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMdUp = useResponsive('up', 'md');
  const { productCart, productCartAction, productWishlist, productWishlistAction } = useSelector(
    (state) => state.product
  );
  const { userCheckInDate, userCheckOutDate } = useSelector((state) => state.general);
  const [selectedVariation, setVariation] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(1);
  const [selectedVariationPrice, setSelectedVariationPrice] = useState({
    oriPrice: 0,
    promoPrice: 0,
    discount: 0,
    percent: false,
    isDiscount: false,
  });
  const [variationStock, setVariationStock] = useState('');
  const [isOverStock, setOverStock] = useState(false);
  const [isWishlistExist, setIsWishlistExist] = useState(false);
  const [cartType, setCartType] = useState(null);
  const [wishlistAction, setWishlistAction] = useState('');
  const QuantitySelection = Array.from({ length: 100 }, (_, index) => index + 1);
  const [notification, setNotification] = useState({
    open: false,
    msg: '',
    type: 'success',
  });

  const [addOnDialog, setAddOnDialog] = useState(null);

  useEffect(() => {
    if (user && user.UserID) {
      dispatch(viewProductWishlist(user?.UserID));
      dispatch(viewProductCart(user?.UserID));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (productCartAction.length > 0) {
      // dispatch(viewProductCart(user?.UserID));
      dispatch(clearProductCartAction());
      setNotification({
        open: true,
        msg: 'Updated Cart',
        type: 'success',
      });
      setAddOnDialog(null);
      setCartType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCartAction]);

  useEffect(() => {
    if (productWishlist.length > 0) {
      const filterData = productWishlist.filter(
        (x) => x.ProductID === productId && x.isHotel === 0
      );
      if (filterData.length > 0) setIsWishlistExist(true);
      else setIsWishlistExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productWishlist]);

  useEffect(() => {
    if (productWishlistAction.length > 0) {
      dispatch(viewProductWishlist(user?.UserID));
      dispatch(clearProductWishlistAction());

      if (wishlistAction === 'add')
        setNotification({ open: true, msg: 'Added to Wishlist', type: 'success' });
      else setNotification({ open: true, msg: 'Removed from Wishlist', type: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productWishlistAction]);

  useEffect(() => {
    if (variation.length > 0 && variation[0].ProductVariation === 'None') {
      handleVariationDetails(
        variation[0].ProductVariationDetailID,
        variation[0].ProductVariationPrice,
        variation[0].ProductStockAmount
      );
      setVariation(variation[0].ProductVariationDetailID);
      if (variation[0].ProductStockAmount >= selectedAmount) setOverStock(false);
      else setOverStock(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variation]);

  const handleVariationChange = (event) => {
    setVariation(Number(event.target.value));
    handleCheckVariationStock(Number(event.target.value), selectedAmount);
  };

  const handleAmountChange = (event, data) => {
    if (data !== '' && typeof data === 'number') {
      setSelectedAmount(Number(data));
      handleCheckVariationStock(selectedVariation, Number(data));
    }
  };

  const handleAddQuantity = (data) => {
    if (data !== '' && typeof data === 'number') {
      setSelectedAmount(Number(data) + 1);
      handleCheckVariationStock(selectedVariation, Number(data) + 1);
    }
  };

  const handleMinusQuantity = (data) => {
    if (data > 1) {
      setSelectedAmount(Number(data) - 1);
      handleCheckVariationStock(selectedVariation, Number(data) - 1);
    } else setNotification({ open: true, msg: 'Minimum 1 item is required', type: 'error' });
  };

  const handleCheckVariationStock = (data, quantity) => {
    if (data !== '') {
      const filterData = variation.filter((x) => x.ProductVariationDetailID === data);
      if (filterData.length > 0) {
        handleVariationDetails(
          data,
          filterData[0].ProductVariationPrice,
          filterData[0].ProductStockAmount
        );

        if (filterData[0].ProductStockAmount >= quantity) setOverStock(false);
        else setOverStock(true);
      } else
        setNotification({
          open: true,
          msg: 'There is an error while adding product quantity',
          type: 'error',
        });
    }
  };

  const handleProceedToCart = (data, productData) => {
    if (user && user.UserID) {
      const processedPromoData = [];
      const filterList = [];

      data.forEach((details) => {
        const variationData = details.variationDetail[0];
        const oriPrice = variationData.ProductVariationPrice;
        let promoPrice = variationData.ProductVariationPrice;
        let discount = 'false';
        let purchaseLimit = variationData.ProductStockAmount;
        let stockLimit = variationData.ProductStockAmount;
        let promoID = 0;
        let promoTitle = '';
        const promotionData = details.ProductPromotion !== '[]' ? details.ProductPromotion : {};

        if (details.ProductPromotion !== '[]' && JSON.parse(details.ProductPromotion).length > 0) {
          const promoData = JSON.parse(details.ProductPromotion)[0];
          promoPrice = promoData.PromotionPrice;
          discount = promoData.ProductDiscount.toString();
          purchaseLimit = promoData.ProductPurchaselimit;
          stockLimit = promoData.ProductStockAvailable;
          promoID = promoData.PromotionID;
          promoTitle = promoData.PromotionTitle;
        }

        processedPromoData.push({
          ...details,
          ProductPromotion: promotionData,
          MerchantShopName: details.ShopName,
          UserCartID: 0,
          UserID: user?.UserID,
          isHotel: 0,
          isEvent: 0,

          CheckinDate: new Date(productData.StartDate),
          CheckinDate113: productData.StartDate,
          CheckoutDate: new Date(productData.EndDate),
          CheckoutDate113: productData.EndDate,

          ProductQuantity: details.quantity,
          ProductVariationDetailID: variationData.ProductVariationDetailID,
          ProductImage: details.ProductImage,
          ProductVariationValue: variationData.ProductVariation,
          ProductPrice: promoPrice,
          OriProductPrice: oriPrice,
          Discount: discount,
          ProductPurchaselimit: purchaseLimit,
          ProductStockLimit: stockLimit,
          PromotionID: promoID,
          PromotionTitle: promoTitle,
          Remarks: '',
          PromoCode: '',
          PromoCodeID: 0,
          PromoCodeDiscount: 0,
          ACCESSCATEGORYID: 1,
        });
      });

      const removeDuplicate = processedPromoData.filter(
        (ele, ind) =>
          ind === processedPromoData.findIndex((elem) => elem.MerchantID === ele.MerchantID)
      );

      if (removeDuplicate.length > 0) {
        removeDuplicate.forEach((x) => {
          const filterDetails = processedPromoData.filter((y) => y.MerchantID === x.MerchantID);
          filterList.push({
            ...x,
            cartList: filterDetails,
          });
        });
      }
      const obj = {
        merchantLising: filterList,
        cartLising: processedPromoData,
        orderData: null,
        isFromBookLah: false,
        isFromEmporiaApp: false,
      };
      navigate(paths.eCommerce.cart, { state: obj });
    } else {
      navigate(paths.loginCover);
    }
  };

  const handleWishlist = () => {
    if (user && user.UserID) {
      if (isWishlistExist === true) {
        const filterData = productWishlist.filter(
          (x) => x.ProductID === productId && x.isHotel === 0
        );
        if (filterData.length > 0) {
          dispatch(deleteProductWishlist(filterData[0].UserWishlistID));
          setIsWishlistExist(false);
          setWishlistAction('delete');
        } else
          setNotification({
            open: true,
            msg: 'There is an error while delete wishlist',
            type: 'error',
          });
      } else {
        dispatch(addProductWishlist(user?.UserID, productId, 0, 0));
        setIsWishlistExist(true);
        setWishlistAction('add');
      }
    } else {
      navigate(paths.loginCover);
    }
  };

  const handleClose = () => {
    setNotification({ open: false, msg: '', type: '' });
  };

  const handleVariationDetails = (selectedId, selectedPrice, selectedStock) => {
    let obj = {
      oriPrice: selectedPrice,
      promoPrice: selectedPrice,
      discount: 0,
      percent: false,
      isDiscount: false,
    };
    let stock = selectedStock;
    if (selectedId !== '') {
      if (promotion.length > 0) {
        const filterData = promotion.filter(
          (x) => x.ProductVariationDetailID === selectedId && x.ProductID === productId
        );

        if (filterData.length > 0 && filterData[0].ProductStockAvailable > 0) {
          obj = {
            oriPrice: selectedPrice,
            promoPrice: filterData[0].PromotionPrice,
            discount: filterData[0].ProductDiscount,
            percent: true,
            isDiscount: true,
          };
          stock = filterData[0].ProductStockAvailable;
        }
      }
    }
    setSelectedVariationPrice(obj);
    setVariationStock(stock);
  };

  const actions = [
    {
      name: 'Facebook',
      icon: (
        <FacebookShareButton
          className="fadeIn"
          openShareDialogOnClick
          url={`https://maren.com.my/product/${productId}`}
          quote="Check this out at Abba Paradise, Get it now!"
          hashtag="#AbbaParadise"
        >
          <FacebookIcon size={35} round />
        </FacebookShareButton>
      ),
    },

    {
      name: 'Telegram',
      icon: (
        <TelegramShareButton
          className="fadeIn"
          openShareDialogOnClick
          title={`Check this out at Abba Paradise, Get it now!${name}`}
          url={`https://maren.com.my/product/${productId}`}
        >
          <TelegramIcon size={35} round />
        </TelegramShareButton>
      ),
    },
    {
      name: 'Twitter',
      icon: (
        <TwitterShareButton
          className="fadeIn"
          openShareDialogOnClick
          url={`https://maren.com.my/product/${productId}`}
        >
          <TwitterIcon size={35} round />
        </TwitterShareButton>
      ),
    },
    {
      name: 'Whatsapp',
      icon: (
        <WhatsappShareButton
          className="fadeIn"
          openShareDialogOnClick
          title={`Check this out at Abba Paradise, Get it now! ${name}`}
          separator=" Visit Now : "
          url={`https://maren.com.my/product/${productId}`}
        >
          <WhatsappIcon size={35} round />
        </WhatsappShareButton>
      ),
    },
  ];

  const handleAddOnDialog = (type) => {
    if (user && user.UserID) {
      if (isOverStock === true && selectedVariation !== '')
        setNotification({ open: true, msg: 'Insufficient stock for your purchase', type: 'error' });
      else if (isOverStock === false && selectedVariation !== '') {
        setAddOnDialog(JSON.parse(product?.ProductRecommendation));
        setCartType(type);
      } else
        setNotification({ open: true, msg: 'Please select required variation', type: 'warning' });
    } else {
      navigate(paths.loginCover);
    }
  };

  const handleCloseAddOnDialog = () => {
    setAddOnDialog(null);
  };

  const handleSelectMerchandise = (data) => {
    const checkInDate = product?.StartDate;
    const checkOutDate = product?.EndDate;
    const allCartData = [
      ...data,
      {
        variation: selectedVariation,
        product: productId,
        quantity: selectedAmount,
        ...product,
        variationDetail: variation,
        ProductImage:
          ProductimageData && ProductimageData.length > 0
            ? ProductimageData[0].ProductMediaUrl
            : '',
      },
    ];

    if (cartType === 'cart') {
      const addProductID = [];
      const addQuantity = [];
      const addVariationId = [];
      const addStartDate = [];
      const addEndDate = [];
      const addAccessCategory = [];

      const updateCartID = [];
      const updateQuantity = [];
      const updateCategoryID = [];

      const productCartListing =
        productCart && productCart[0].ProductCart !== '[]'
          ? JSON.parse(productCart[0].ProductCart)
          : [];

      allCartData.forEach((details) => {
        const filterData = productCartListing.filter(
          (x) =>
            x.ProductVariationDetailID === details.variation &&
            x.ProductID === details.product &&
            moment(x.CheckinDate).format('YYYY-MM-DD') === checkInDate &&
            moment(x.CheckoutDate).format('YYYY-MM-DD') === checkOutDate
        );
        if (filterData.length > 0) {
          updateCartID.push(filterData[0].UserCartID);
          updateQuantity.push(details.quantity + Number(filterData[0].ProductQuantity));
          updateCategoryID.push(checkAccessCategory(0, 0));
        } else {
          addProductID.push(details.product);
          addQuantity.push(details.quantity);
          addVariationId.push(details.variation);
          addStartDate.push(checkInDate);
          addEndDate.push(checkOutDate);
          addAccessCategory.push(checkAccessCategory(0, 0));
        }
      });
      if (updateCartID.length > 0) {
        dispatch(updateProductCart(user?.UserID, updateCartID, updateQuantity, updateCategoryID));
      }
      if (addProductID.length > 0) {
        dispatch(
          addProductCart(
            user?.UserID,
            addProductID,
            addQuantity,
            addVariationId,
            0,
            addStartDate,
            addEndDate,
            addAccessCategory
          )
        );
      }
    } else {
      handleProceedToCart(allCartData, product);
    }
  };

  return (
    <>
      {selectedVariation !== '' && variationStock > 0 && (
        <Label color="success" sx={{ mb: 3 }}>
          In Stock ({variationStock})
        </Label>
      )}
      {selectedVariation !== '' && variationStock <= 0 && (
        <Label color="error" sx={{ mb: 3 }}>
          Out Of Stock
        </Label>
      )}

      {selectedVariation === '' && variation.filter((x) => x.ProductStockAmount > 0).length > 0 && (
        <Label color="success" sx={{ mb: 3 }}>
          In Stock
        </Label>
      )}
      {selectedVariation === '' &&
        variation.filter((x) => x.ProductStockAmount > 0).length === 0 && (
          <Label color="error" sx={{ mb: 3 }}>
            Out Of Stock
          </Label>
        )}

      <Stack spacing={1} sx={{ mb: 2 }}>
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Typography
            variant="h4"
            onClick={() => isQuickview === 'true' && navigate(paths.eCommerce.product(productId))}
          >
            {' '}
            {name}{' '}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Iconify
            icon="ant-design:shop-filled"
            onClick={() => navigate(paths.eCommerce.merchantProduct(merchantID))}
          />
          <Typography
            variant="subtitle2"
            pl={1}
            onClick={() => navigate(paths.eCommerce.merchantProduct(merchantID))}
          >
            {' '}
            {shopName}{' '}
          </Typography>
          {isQuickview === 'true' && (
            <Typography
              variant="caption"
              pl={1}
              sx={{ textDecoration: 'underline', color: 'grey' }}
              onClick={() => navigate(paths.eCommerce.product(productId))}
            >
              {' '}
              View Product Details
            </Typography>
          )}
        </Stack>

        <Stack spacing={0.5} direction="row" alignItems="center">
          <Rating size="small" value={rating} readOnly precision={0.5} />

          <Typography variant="caption" sx={{ color: 'text.disabled' }}>
            ({review} reviews)
          </Typography>
        </Stack>
      </Stack>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {caption}
      </Typography>

      <Stack spacing={2} pt={2}>
        {Number(selectedVariationPrice.oriPrice) ? (
          <Stack>
            <ProductPricing
              price={Number(selectedVariationPrice.promoPrice)}
              priceSale={Number(selectedVariationPrice.oriPrice)}
              discount={selectedVariationPrice.discount}
              isDiscount={selectedVariationPrice.isDiscount}
              percent={selectedVariationPrice.percent}
              sx={{ typography: 'h5' }}
            />
            <Typography variant="subtitle1" color="#3C4C24" pt={1}>
              {product?.StartDate === product?.EndDate
                ? `${product?.StartDate}`
                : `${product?.StartDate} - ${product?.EndDate}`}{' '}
              ( {numberofday > 1 ? `${numberofday} Days ` : '1 Day '}){' '}
            </Typography>
          </Stack>
        ) : (
          <Stack>
            <Typography sx={{ typography: 'subtitle1', fontSize: '18px', pt: 0.2 }}>
              {' '}
              {price === 0 ? fCurrency('0') : price}
            </Typography>
            <Typography variant="subtitle1" color="#3C4C24" pt={1}>
              {product?.StartDate === product?.EndDate
                ? `${product?.StartDate}`
                : `${product?.StartDate} - ${product?.EndDate}`}
              ( {numberofday > 1 ? `${numberofday} Days ` : '1 Day '}){' '}
            </Typography>
          </Stack>
        )}
      </Stack>
      {(moment(userCheckInDate).format('YYYY-MM-DD') !== product?.StartDate ||
        moment(userCheckOutDate).format('YYYY-MM-DD') !== product?.EndDate) && (
        <Typography variant="body2" color="red" pt={1}>
          ** Kindly click on the search icon to search for desired date pricing
        </Typography>
      )}

      <Stack spacing={3} sx={{ my: 5 }}>
        {variation.length > 0 && variation[0].ProductVariation !== 'None' && (
          <Stack spacing={2}>
            <Typography variant="subtitle2">Variation</Typography>
            <ProductCustomizeOptionPicker
              value={selectedVariation}
              onChange={handleVariationChange}
              options={variation}
              optionLabel="ProductVariationValue"
              optionValue="ProductVariationDetailID"
              optionStock="ProductStockAmount"
            />
          </Stack>
        )}
      </Stack>

      {notification.open && notification.type !== 'success' && (
        <Stack spacing={3} sx={{ my: 5 }}>
          <Stack spacing={2}>
            <Alert severity={notification.type} sx={{ width: '100%' }}>
              {notification.msg}
            </Alert>
          </Stack>
        </Stack>
      )}

      {notification.open === false && variationStock < selectedAmount && (
        <Stack spacing={3} sx={{ my: 2 }}>
          <Stack spacing={2}>
            <Alert severity="error" sx={{ width: '100%' }}>
              Insufficient stock for your purchase
            </Alert>
          </Stack>
        </Stack>
      )}

      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ md: 'center' }}>
        <QuantityControl
          value={selectedAmount}
          onAdd={(e) => handleAddQuantity(e)}
          onMinus={(e) => handleMinusQuantity(e)}
          mt={-10}
          mb={10}
          width="50%"
        />

        {/* <Autocomplete          
          id="cartQuantity"
          options={QuantitySelection}
          getOptionLabel={(option) => String(option)}          
          value={selectedAmount}
          onChange={handleAmountChange}
          sx={{ width: 150 }}
          renderInput={(params) => <TextField {...params} label="Quantity" />}
        /> */}

        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => handleAddOnDialog('cart')}
            fullWidth={!isMdUp}
            size="large"
            color="inherit"
            variant="contained"
            disabled={!variationStock > 0 || variationStock < selectedAmount}
            startIcon={<Iconify icon="carbon:shopping-cart-plus" />}
          >
            Add to Cart
          </Button>

          <Button
            onClick={() => handleAddOnDialog('buynow')}
            fullWidth={!isMdUp}
            size="large"
            color="primary"
            variant="contained"
            disabled={!variationStock > 0 || variationStock < selectedAmount}
          >
            Buy Now
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed', my: 3 }} />

      <Stack spacing={3} direction="row">
        {/* <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle2' }}>
          <Button
            variant='none'
          >
            <Iconify icon="carbon:add-alt" sx={{ mr: 1 }} /> Compare
          </Button>
        </Stack> */}

        <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle2' }}>
          <Button variant="none" color="inherit" onClick={() => handleWishlist()}>
            <Iconify icon="carbon:favorite" sx={{ mr: 1, color: isWishlistExist && 'red' }} />{' '}
            Wishlist
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle2' }}>
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<Iconify icon="carbon:share" sx={{ mr: 1 }} />}
          >
            {actions.map((action) => (
              <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
            ))}
          </SpeedDial>
        </Stack>
      </Stack>

      {/* <AddOnDialog onCancel={handleCloseAddOnDialog} onConfirm={handleSelectMerchandise} isOpen={addOnDialog !== null} products={addOnDialog} dateSelection={product?.StartDate === product?.EndDate ? `${product?.StartDate} ( 1 Day )` : `${product?.StartDate} - ${product?.EndDate} ( ${numberofday} Days )`} /> */}

      <Notification notification={notification} setNotification={setNotification} />
    </>
  );
}
