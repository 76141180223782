/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { Box, Stack, Alert } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MuiOtpInput } from 'mui-one-time-password-input';
import { LoadingButton } from '@mui/lab';

OTPPage.propTypes = {
  email: PropTypes.string.isRequired,
  CallOTP_Verification: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  ResendOTP: PropTypes.func,
  errorMsg: PropTypes.string,
  setError: PropTypes.func,
};

export default function OTPPage({
  setError,
  errorMsg,
  email,
  onClose,
  open,
  CallOTP_Verification,
  ResendOTP,
}) {
  const [OTP, setOTP] = useState('');
  const [countDown, setCountDown] = useState(60);
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    if (open) {
      setCountDown(60);
    }
  }, [open]);

  useEffect(() => {
    if (errorMsg && errorMsg !== '') {
      setOTP('');
      setIsSubmiting(false);
    }
  }, [errorMsg]);


  useEffect(() => {
    if (open && countDown > 0) {
      const interval = setInterval(() => {
        setCountDown((prevCountDown) => prevCountDown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [open, countDown]);

  const handleChange = (otp) => {
    if (otp !== null) {
      setOTP(otp);
    }
    if (otp.length === 6) {
      setError('afterSubmit', {
        message: '',
      });
      setIsSubmiting(true);
      CallOTP_Verification(otp);
      setCountDown(0);
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('text/plain');
    const newOTP = pasteData.replace(/\D/g, '').slice(0, 6);
    setOTP(newOTP);
    if (newOTP.length === 6) {
      setIsSubmiting(true);
      CallOTP_Verification(newOTP);
      setCountDown(0);
    }
    event.preventDefault();
  };

  const censorEmail = (curr_email) => {
    if (curr_email !== null && curr_email.length > 5) {
      const arr = curr_email.split('@');
      return `${censorWord(arr[0])}@${arr[1]}`;
    }
    return 'No email was found';
  };

  const censorWord = (str) => str[0] + '*'.repeat(str.length - 2) + str.slice(-1);

  const handleSubmit = async (otp) => {
    try {
      setCountDown(0);
      setOTP('');
    } catch (error) {
      console.error(error);
    }
  };

  const handleResendOTP = () => {
    ResendOTP();
    setCountDown(60);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{`Please check your email ${censorEmail(
        email
      )} for verification code!`}</DialogTitle>
      <DialogContent>
        {errorMsg && errorMsg !== '' && <Alert severity="error">{errorMsg}</Alert>}

        <MuiOtpInput
          pt={3}
          id="OTP"
          label="OTP"
          variant="outlined"
          className="w-100"
          length={6}
          value={OTP}
          onChange={handleChange}
          onPaste={handlePaste}
        />

        <Stack pt={2} sx={{ textAlign: 'center' }}>
          <Typography variant="body2">Kindly spam check inbox, do check also spam mail</Typography>
        </Stack>

        {countDown === 0 && (
          <Stack direction="row" pt={2} sx={{ justifyContent: 'center' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              {' '}
              Didn't receive mail? &nbsp;{' '}
            </Typography>
            <Typography
              variant="caption"
              sx={{ textDecoration: 'underline', color: '#4b8354', fontWeight: 'bold' }}
              onClick={() => handleResendOTP()}
            >
              {' '}
              Resent OTP{' '}
            </Typography>
          </Stack>
        )}
        {countDown !== 0 && (
          <Stack pt={1} sx={{ textAlign: 'center', color: '#4b8354' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              {countDown} Seconds
            </Typography>
          </Stack>
        )}

        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions className="d-flex justify-content-between">
        <LoadingButton
          loading={isSubmiting}
          fullWidth
          disabled={OTP.length !== 6}
          onClick={() => {
            handleSubmit(OTP);
          }}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export function useCountdown() {
  const [countDown, setCountDown] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((prevCountDown) => prevCountDown - 1);
    }, 1000);

    if (countDown === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [countDown]);

  return countDown;
}
