export default {
  auth: 'abbaParadiseApp',
  sellerId: 'SE00055564',
  sellerExId: 'EX00012067',
  productDesc: 'AbbaParadise Product',
  version: '6.0',
  currency: 'MYR',
  sellerBankCode:'01',
  msgToken: '01',
  msgType:'AR',
  msgTypeAE: 'AE',

  transactionType:'sale',
  locale:'en',
  country:'MY',
  accessKey: '51f40be210ff34cba0079b19efd3ab42',
  profileId: '0CE666B6-7064-4D68-9DFE-EC46776C02A4',
  APIkey:'2c57e2f0161a450ebe5fb67ffbdd51fc196b0256ed1940158f54990b57f4ec3c1e08823fa84c4596bea898bb2b53e6d124414d118b954914806c182092123d4008ba628a8eaf403faa7e3c1adb470ee9d6044313451442d2acd532b47d42e00a2fdecfa996334065a94e0d46d32b7534b3fb4016198047568afd83c99823f6ed', 

  toyyibKey : '0w34b0y1-n5l4-f78a-palz-e5x19ha225h1',
  billerCode :'d8qezq2v'
}
