import { createSlice } from '@reduxjs/toolkit';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
import { setUser } from '../../auth/utils';

// ----------------------------------------------------------------------

const url = ServerConfiguration.eventApiUrl;
const urlEmporia = ServerConfiguration.testingServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  ticketValidity: null,
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    getTicketValiditySuccess(state, action) {
      state.isLoading = false;
      state.ticketValidity = action.payload;
    },

    clearTicketValiditySuccess(state, action) {
      state.isLoading = false;
      state.ticketValidity = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEvents(merchantId, productPage, page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_ItemListByMerchantID?MERCHANTID=${merchantId}&PROJECTID=${PROJECTID}&PRODUCTPERPAGE=${productPage}&PAGE=${page}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getEventsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getEventsSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEventsByTag(eventnametag, productPage, page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_ItemListByEventNameTag?EVENTNAMETAG=${eventnametag}&PROJECTID=${PROJECTID}&PRODUCTPERPAGE=${productPage}&PAGE=${page}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getEventsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getEventsSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function checkEventTicketValidity(promotionId, ticketId, ticketQuantity) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_CheckPromoAvailability?PROMOTIONID=${promotionId}&TICKETID=${ticketId}&TICKETQUANTITY=${ticketQuantity}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getTicketValiditySuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearEventTicketValidity() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearTicketValiditySuccess(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------