import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import {
  Stack,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions, Grid,
  Badge, Paper,
  Box, FormControl,
  Select,
  MenuItem,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import { fCurrency } from 'src/utils/formatNumber';
import { NAV } from 'src/config-global';
import Scrollbar from 'src/components/scrollbar';
import Image from 'src/components/image';
import { EcommerceProductItemAddOn } from 'src/sections/_e-commerce/product/item';
import { ProductPricing } from 'src/sections/_e-commerce/components';
import { QuantityControl } from 'src/components/hook-form';

import EmptyContent from 'src/pages/EmptyContent';
import EmptyContentImage from 'src/assets/illustrations/illustration_empty_content.svg';
import useResponsive from 'src/hooks/useResponsive';
import { tagDropdown } from 'src/utils/helpers'
// ----------------------------------------------------------------------

AddOnDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  products: PropTypes.array,
  setNotification: PropTypes.func,
  dateSelection: PropTypes.string,
};

export default function AddOnDialog({ onConfirm, onCancel, isOpen, products, setNotification, dateSelection }) {
  const isXsUp = useResponsive('up', 'xs');
  const [selectedVariation, setSelectedVariation] = useState({
    variation: 0,
    product: 0,
    productName: "",
    productBrand: "",
    productVariation: "",
    productPrice: 0,
    productDiscountPrice: 0,
    productImage: "",
    quantity: 1
  });
  const [tempCart, setTempCart] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [SORT_OPTIONS, setSortOption] = useState(null);
  const [sort, setSort] = useState('All');
  const [productFilterListing, setProductFilterListing] = useState([]);

  const ITEM_SPACING = 1;
  const PARENT_ITEM_HEIGHT = 64;

  useEffect(() => {
    if (products && products !== undefined) {
      setSortOption(tagDropdown(products));
      setProductFilterListing(products)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const handleVariationChange = (data, product) => {

    setSelectedVariation({
      ...product,
      variationDetail: JSON.parse(product.ProductVariation),
      productStock: data.ProductStockAmount,
      variation: data.ProductVariationDetailID,
      product: product.ProductID,
      productName: product.ProductName,
      productBrand: product.Brand,
      productVariation: data.ProductVariationValue,
      productDiscountPrice: product.DiscountedProductPrice ? product.DiscountedProductPrice : 0,
      productPrice: product.ProductPrice ? product.ProductPrice : 0,
      productImage: product.ProductImage,
      quantity: 1
    });
  }

  const handleConfirm = () => {
    onConfirm(tempCart)
    setSelectedVariation({
      variation: 0,
      product: 0,
      productName: "",
      productBrand: "",
      productVariation: "",
      productPrice: 0,
      productDiscountPrice: 0,
      productImage: "",
      quantity: 1
    })
    setTempCart([])
  }

  const handleCancel = () => {
    setSelectedVariation({
      variation: 0,
      product: 0,
      productName: "",
      productBrand: "",
      productVariation: "",
      productPrice: 0,
      productDiscountPrice: 0,
      productImage: "",
      quantity: 1
    })
    setTempCart([])
    onCancel()
  }

  const handleCheckButton = () => {
    let isDisabled = false
    if (selectedVariation.variation === 0 && selectedVariation.product !== 0)
      isDisabled = true

    if (tempCart && tempCart.filter((x) => x.productStock < x.quantity).length > 0)
      isDisabled = true

    return isDisabled
  }

  const handleMinusQuantity = (data,) => {
    if (data > 1)
      setSelectedVariation({ ...selectedVariation, quantity: data - 1 })
    else
      setNotification({ open: true, msg: "Minimum 1 item is required", type: "error" })
  }

  const handleAddQuantity = (data) => {
    setSelectedVariation({ ...selectedVariation, quantity: data + 1 })
  }


  const handleAddTempCart = (data) => {
    if (tempCart.length > 0) {
      const ListingIndex = tempCart.findIndex(x => x.variation === data.variation && x.product === data.product)
      if (ListingIndex === -1)
        setTempCart(oldArray => [...oldArray, data]);
      else {
        const listing = [...tempCart]
        listing[ListingIndex].quantity = Number(listing[ListingIndex].quantity) + Number(data.quantity)
        setTempCart(listing)
      }

    } else
      setTempCart(oldArray => [...oldArray, data]);

  }

  const handleRemoveCart = (data) => {
    const listing = [...tempCart]
    const filterListing = listing.filter((x) => Number(x.variation) !== Number(data.variation))
    setTempCart(filterListing)
  }

  const handleMinusCartQuantity = (data, variationPass) => {
    if (data > 1) {
      const listing = [...tempCart]
      const ListingIndex = tempCart.findIndex(x => x.variation === variationPass.variation && x.product === variationPass.product)
      listing[ListingIndex].quantity = data - 1
      setTempCart(listing)
    }
    else
      setNotification({ open: true, msg: "Minimum 1 item is required", type: "error" })
  }

  const handleAddCartQuantity = (data, variationPass) => {
    const listing = [...tempCart]
    const ListingIndex = tempCart.findIndex(x => x.variation === variationPass.variation && x.product === variationPass.product)
    listing[ListingIndex].quantity = data + 1
    setTempCart(listing)
  }

  const handleClose = () => {
    setOpenCart(false)
  }

  const handleChangeSort = (event) => {
    setSort(event.target.value);

    if (event.target.value === 'All') {
      setProductFilterListing(products);
    } else {
      const filteredProducts = products.filter((product) =>
        product.ProductTag.split(',').includes(event.target.value)
      );
      setProductFilterListing(filteredProducts);
    }
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      onClose={() => handleCancel()}
    >
      <DialogTitle>
        <Stack direction={{ sm: "row", xs: "column" }} spacing={2} justifyContent="space-between" >
          <Stack direction="row" spacing={2} alignItems="center" textAlign="justify">
            <Stack>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                <Iconify icon="emojione:party-popper" width={40} height={40} />  Add-Ons
              </Typography>
              <Typography variant="subtitle2" >
                Would you like to add any additional products? Check out these recommended add-ons to complement your item
              </Typography>
              <Typography variant="caption" sx={{ color: "red" }}>
                **  Select the items you want to add to your cart and click Confirm to include them with your current order.
              </Typography>
              <Typography variant="h6" >
                Below Item is price for : {dateSelection}
              </Typography>
            </Stack>
          </Stack>


          <Stack direction="flex-end" justifyContent="flex-end" pr={2} pt={{ xs: 0, sm: 1 }}>
            <Badge badgeContent={tempCart.length} color="error" onClick={() => setOpenCart(true)}>
              <Iconify icon="carbon:shopping-cart" width={24} />
            </Badge>
          </Stack>
        </Stack>



      </DialogTitle>

      <DialogContent
        sx={{
          pb: 5,
        }}
      >
        <Stack  alignItems="flex-end" pb={2}> 
          <FormControl size="small" hiddenLabel variant="filled" sx={{ width: 200 }}>
            <Typography variant="subtitle2">Category Filter</Typography>
            <Select
              value={sort}
              onChange={handleChangeSort}
              MenuProps={{
                PaperProps: {
                  sx: { px: 1 },
                },
              }}
            >
              {SORT_OPTIONS &&
                SORT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>

        <Box
          gap={{ xs: 2, md: 0 }}
          display="grid"
          gridTemplateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
        >
          {productFilterListing.map((item) => (

            <EcommerceProductItemAddOn product={item}
              key={`${item.ProductID}`} isMerchandise
              handleVariationChange={handleVariationChange} selectedVariation={selectedVariation}
              handleAddQuantity={handleAddQuantity} handleMinusQuantity={handleMinusQuantity} handleAddTempCart={handleAddTempCart}
            />
          ))}

        </Box>
        {
          openCart && (
            <Paper
              onMouseLeave={handleClose}
              sx={{
                p: 3,
                width: { xs: NAV.W_DRAWER, sm: NAV.W_CARTDRAWER },
                position: 'absolute',
                borderRadius: 2,
                top: PARENT_ITEM_HEIGHT,
                right: -ITEM_SPACING * 8,
                zIndex: (theme) => theme.zIndex.modal,
                boxShadow: (theme) => theme.customShadows.z20,
                cursor: 'pointer',
              }}
            >
              <Scrollbar>
                <Typography variant="h5" >Add-Ons Cart</Typography>
                {
                  tempCart.length > 0 ? tempCart.map((details) => (
                    <Stack pt={2} sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }} key={details.variation}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Image
                            src={details.productImage}
                            sx={{
                              width: { xs: 50, sm: 80 },
                              height: { xs: 50, sm: 80 },
                              flexShrink: 0,
                              borderRadius: 1.5,
                              bgcolor: 'background.neutral',
                            }}
                          />
                        </Grid>
                        <Grid item xs={9} pb={1}>
                          <Typography variant="subtitle2" > {details.productName}   </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>   {details.productVariation}  </Typography>
                          <ProductPricing price={Number(details.productPrice ? details.productPrice : "0")} priceSale={Number(details.productPrice ? details.productPrice : "0")} discount={0} percent={false} sx={{ typography: 'subtitle2' }} />

                          <Stack direction="row" justifyContent="space-between" >
                            <QuantityControl value={details.quantity} onAdd={(e) => details.productStock > details.quantity && handleAddCartQuantity(e, details)} onMinus={(e) => handleMinusCartQuantity(e, details)} mt={-15} mb={0} />
                            <Stack direction="flex-end" pt={0.5}>
                              <Iconify icon="carbon:trash-can" color="red" onClick={() => handleRemoveCart(details)} />
                            </Stack>
                          </Stack>
                          {details.productStock < details.quantity && <Typography variant='caption' color='red'>Insufficient stock for your selection</Typography>}
                          <Typography variant="subtitle2" > Total :  {fCurrency(details.productPrice ? details.productPrice * details.quantity : "0")} </Typography>
                        </Grid>
                      </Grid>

                    </Stack>
                  ))
                    :
                    <Stack direction="row" alignItems="center" textAlign="center" justifyContent="space-around">
                      <EmptyContent
                        title="Add On cart is empty"
                        description="Look like you did't select add-ons in your cart."
                        img={EmptyContentImage}
                      />
                    </Stack>
                }
              </Scrollbar>
            </Paper>
          )
        }
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => handleConfirm()}
          disabled={handleCheckButton()}
          color="inherit"
          variant="contained"
        >
          Confirm
        </Button>
        <Button
          onClick={() => handleCancel()}
          color="inherit"
          variant="text"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}