import PropTypes from 'prop-types';//
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function Notification({ notification, setNotification }) {

  const handleClose = () => {
    setNotification({ open: false, msg: '', type: '' });
  };
  const { enqueueSnackbar } = useSnackbar();

  return (
    // display: none is because to hide the snackbar ID showing on the screen
    <div style={{ display: "none" }}>
      {notification.open && enqueueSnackbar(notification.msg, {
        variant: notification.type,
        onClose: handleClose,
        autoHideDuration: 1000,
        preventDuplicate: true,
      })}
    </div>
  );
}

Notification.propTypes = {
  notification: PropTypes.object,
  setNotification: PropTypes.func,
};
