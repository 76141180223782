import { useRef } from 'react';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography, Container } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
//
import Carousel, { CarouselDots, CarouselArrows } from 'src/components/carousel';
import { EcommerceProductItemHot, EcommerceProductViewGridItemSkeleton } from '../item';

// ----------------------------------------------------------------------

EcommerceProductRecommendation.propTypes = {
  productsData: PropTypes.array,
};

export default function EcommerceProductRecommendation({ productsData, ...other }) {
  const theme = useTheme();

  const isMdUp = useResponsive('up', 'md');

  const carouselRef = useRef(null);

  // const getSize 
  const carouselSettings = {
    dots: true,
    arrows: false,
    // rows: 1,
    slidesPerRow: 4,
    slidesToShow: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    ...CarouselDots(),
    responsive: [
        {
            // Down md
            breakpoint: theme.breakpoints.values.md,
            settings: { slidesPerRow: 2 },
        },
        {
            // Down sm
            breakpoint: theme.breakpoints.values.sm,
            settings: { slidesPerRow: 1 },
        },
    ],
};

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Container
      sx={{
        py: { xs: 5, md: 5 },
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        spacing={3}
        sx={{
          mb: 5,
        }}
      >
        <Iconify icon="carbon:favorite" width={30} />
        <Typography
          variant="h3"
          sx={{
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          You may also like
        </Typography>

        {isMdUp && (
          <CarouselArrows
            onNext={handleNext}
            onPrev={handlePrev}
            flexGrow={1}
            spacing={2}
            justifyContent="flex-end"
          />
        )}
      </Stack>

      {/* {isMdUp ? ( */}
        <Carousel ref={carouselRef} {...carouselSettings}>
          {productsData.map((product, index) =>
            product ? (
              <Box
                key={product.ProductID}
                sx={{
                  py: 0.5,
                  px: { xs: 1, md: 1.5 },
                }}
              >
                <EcommerceProductItemHot product={product} isMerchandise={false}/>
              </Box>
            ) : (
              <EcommerceProductViewGridItemSkeleton key={index} />
            )
          )}
        </Carousel>
      {/* ) : (
        <Carousel ref={carouselRef} {...carouselSettings}>

          {productsData.map((product, index) =>
            product ? (
              <Box
                gap={3}
                display="row"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(3, 1fr)',
                  lg: 'repeat(4, 1fr)',
                }}
                sx={{ mb: { xs: 3, md: 2 } }}
              >
                <EcommerceProductItemHot key={product.ProductID} product={product} />
              </Box>
            ) : (
              <EcommerceProductViewGridItemSkeleton key={index} />
            )
          )}
        </Carousel>
      )
      } */}
    </Container>
  );
}
