// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
// routes
import Router from 'src/routes';
import { Provider as ReduxProvider } from 'react-redux';
// theme
import ThemeProvider from 'src/theme';
// components
import ScrollToTop from 'src/components/scroll-to-top';
import { ThemeSettings, SettingsProvider } from 'src/components/settings';
import MotionLazyContainer from 'src/components/animate/MotionLazyContainer';
// redux
import { store, persistor } from './redux/store';

import { AuthProvider } from './auth/JwtContext';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId="1089726873361-rg2eh6mrmac947ofivkecl5mofo6naho.apps.googleusercontent.com">
        <HelmetProvider>
          <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <BrowserRouter basename="/">
                  <ScrollToTop />
                  <ThemeProvider>
                    <ThemeSettings>
                      <MotionLazyContainer>
                        <SnackbarProvider
                          maxSnack={4}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <Router />
                        </SnackbarProvider>
                      </MotionLazyContainer>
                    </ThemeSettings>
                  </ThemeProvider>
                </BrowserRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </ReduxProvider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
}
