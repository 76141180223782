import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Stack,
  Button,
  Divider,
  TextField,
  Typography,
  InputAdornment,
  Alert,
} from '@mui/material';
// utils
import { fCurrency } from 'src/utils/formatNumber';

import { useAuthContext } from '../../../auth/useAuthContext';
// ----------------------------------------------------------------------

export default function EcommerceCartSummary({
  tax,
  total,
  subtotal,
  shipping,
  discount,
  handleNext,
  handleCheckPromo,
  handlePromoCode,
  promoCode,
  validPromoData,
  cartListing,
  orderID,
}) {
  const { user } = useAuthContext();

  const userDiscount = user?.AgentInd === 1 ? user?.AgentDiscount || 0 : 0
  const handleCheckButton = () => {
    let isDisabled = false;
    if (
      cartListing.length > 0 &&
      cartListing.filter((x) => x.ProductQuantity > x.ProductStock).length > 0
    )
      isDisabled = true;

    if (
      cartListing.length > 0 &&
      cartListing.filter((x) => x.Discount !== 'false' && x.ProductQuantity > x.ProductStockLimit)
        .length > 0
    )
      isDisabled = true;

    if (
      cartListing.length > 0 &&
      cartListing.filter(
        (x) => x.Discount !== 'false' && x.ProductQuantity > x.ProductPurchaselimit
      ).length > 0
    )
      isDisabled = true;

    if (cartListing.filter((x) => x.isHotel === 1).length > 0) {
      const hotelList = cartListing.filter((x) => x.isHotel === 1);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      if (hotelList.filter((x) => new Date(x.CheckinDate113) < yesterday).length > 0)
        isDisabled = true;
    }

    return isDisabled;
  };

  return (
    <Stack
      spacing={3}
      sx={{
        p: 3,
        borderRadius: 2,
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
      }}
    >
      <Typography variant="h6"> Summary </Typography>

      <Stack spacing={2}>
        <Row label="Subtotal" value={fCurrency(subtotal)} />

        {/* <Row label="Shipping" value={fCurrency(shipping)} /> */}

        <Row label="Discount" value={`-${fCurrency(discount)}`} />

        {userDiscount > 0 && (
          <Typography variant="caption" color='orange' fontWeight='bold' textAlign='right'>
            {userDiscount} % Agent Discount Applied
          </Typography>
        )}

        <Row label="Service Fee" value={`${fCurrency(tax)}`} />
      </Stack>

      {/* {validPromoData.isChecked === "invalid" && <Alert severity="error" sx={{ width: '100%' }}> Invalid Discount Code </Alert>}
      {validPromoData.isChecked === "valid" && <Alert severity="success" sx={{ width: '100%' }}> Discount Code is Apply </Alert>}
      <TextField
        hiddenLabel
        placeholder="Discount Code"
        disabled={orderID !== ""}
        value={promoCode}
        onChange={(e) => handlePromoCode(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {
                orderID === "" && <Button onClick={handleCheckPromo}>Apply</Button>
              }

            </InputAdornment>
          ),
        }}
      /> */}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Row
        label="Total"
        value={fCurrency(total <= 0 ? '0' : total)}
        sx={{
          typography: 'h6',
          '& span': { typography: 'h6' },
        }}
      />

      <Button
        // component={RouterLink}
        // to={paths.eCommerce.checkout}
        disabled={handleCheckButton()}
        onClick={() => handleNext()}
        size="large"
        variant="contained"
        color="inherit"
      >
        Checkout
      </Button>
    </Stack>
  );
}

EcommerceCartSummary.propTypes = {
  discount: PropTypes.number,
  shipping: PropTypes.number,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  total: PropTypes.number,
  handleNext: PropTypes.func,
  handlePromoCode: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  promoCode: PropTypes.string,
  validPromoData: PropTypes.object,
  cartListing: PropTypes.array,
  orderID: PropTypes.string,
};

// ----------------------------------------------------------------------

function Row({ label, value, sx, ...other }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ typography: 'subtitle2', ...sx }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'body2' }}>
        {label}
      </Box>
      {value}
    </Stack>
  );
}

Row.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.object,
  value: PropTypes.string,
};
