import PropTypes from 'prop-types';
// @mui
import { Stack, TextField, IconButton, Typography, Grid, Rating } from '@mui/material';
// utils
import { fCurrency } from 'src/utils/formatNumber';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router';
// routes
import { paths } from 'src/routes/paths';
// components
import Logo from 'src/assets/abbaparadise.png';

// ----------------------------------------------------------------------

export default function EcommerceWishlistView({ product, wishlist, handleDeleteWishlist }) {

  const navigate = useNavigate()

  const handleNavigate = () => {
    if (product.isHotel === 0 && product.isEvent === 0)
      navigate(paths.eCommerce.product(product.ProductID))
    else
      navigate(paths.hotel.list(product.ProductID))
  }

  return (
    <Stack sx={{
      py: 3,
      // minWidth: 720,
      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
    }}>
  
        <Stack
          direction="row"
          alignItems="center"
        >
          <Grid container>
            <Grid item lg={7.5} md={7.4} sm={7.2} xs={6}>
              <Stack sx={{flexDirection:{xs:"column", sm:"row"}}} alignItems="center" flexGrow={1} 
              onClick={handleNavigate}
              // onClick={() => navigate(paths.eCommerce.product(product.ProductID))}
              >

                <Image
                  src={product.ProductImage ? product.ProductImage : Logo}
                  sx={{
                    width: 80,
                    height: 80,
                    flexShrink: 0,
                    borderRadius: 1.5,
                    bgcolor: 'background.neutral',
                  }}
                />

                <Stack spacing={0.5} sx={{ p: 2 }} >
                  <Typography variant="subtitle2">{product.ProductName}</Typography>
                  <Stack spacing={0.5} direction="row" alignItems="center">
                    <Rating size="small" value={product.ProductRating} readOnly precision={0.5} />

                    <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                      ({product.ProductReviewCount} reviews)
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={2} md={2} sm={1.8} xs={3}>
            <Typography variant="subtitle2">{product.isHotel === 0 ? fCurrency(product.ProductPrice ? product.ProductPrice : "0" ) : `RM ${product.ProductPrice}`}</Typography>
            </Grid>

            <Grid item lg={2} md={2} sm={3} xs={3}>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" flexGrow={1}>
                <IconButton onClick={() => handleNavigate()} >
                  <Iconify icon="carbon:shopping-cart-plus" />
                </IconButton>

                <IconButton onClick={() => handleDeleteWishlist(product.UserWishlistID)}>
                  <Iconify icon="material-symbols:delete-outline"  />
                </IconButton>
              </Stack>

            </Grid>
          </Grid>
        </Stack>
    </Stack>
  );
}

EcommerceWishlistView.propTypes = {
  product: PropTypes.shape({
    isHotel: PropTypes.number,
    isEvent: PropTypes.number,
    ProductID: PropTypes.number,
    ProductImage: PropTypes.string,
    ProductName: PropTypes.string,
    ProductPrice: PropTypes.string,
    ProductPromotion: PropTypes.object,
    ProductVariationValue: PropTypes.string,
    ProductStock: PropTypes.number,
    ProductQuantity: PropTypes.number,
    MerchantShopProductName: PropTypes.string,
    MerchantID: PropTypes.number,
    UserWishlistID: PropTypes.number,
    ProductRating: PropTypes.number,
    ProductReviewCount: PropTypes.number,
  }),
  wishlist: PropTypes.bool,
  handleDeleteWishlist: PropTypes.func,
};
