import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/assets/abbaparadise.png';
// @mui
import { alpha } from '@mui/material/styles';
import { Stack, Button, Link, Typography, Rating, IconButton } from '@mui/material';
// utils
import { fCurrency } from 'src/utils/formatNumber';
// routes
import { paths } from 'src/routes/paths';
// components
import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router';
//

// ----------------------------------------------------------------------

export default function EcommerceWishlistItemCard({ product, wishlist, handleDeleteWishlist, sx, ...other }) {

    const navigate = useNavigate()

    const handleNavigate = () => {
        if (product.isHotel === 0)
            navigate(paths.eCommerce.product(product.ProductID))
        else
            navigate(paths.hotel.list(product.ProductID))
    }

    return (
        <Link component={RouterLink} to={paths.eCommerce.product(product.ProductID)} color="inherit" underline="none">
            <Stack
                spacing={2}
                direction="row"
                sx={{
                    p: 2,
                    borderRadius: 2,
                    border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
                    ...sx,
                }}
                {...other}
            >
                <Image
                    src={product.ProductImage ? product.ProductImage : Logo}
                    sx={{
                        width: 90,
                        height: 90,
                        flexShrink: 0,
                        borderRadius: 1.5,
                        bgcolor: 'background.neutral',
                    }}
                />

                <Stack spacing={0.5} flexGrow={1}>
                    <TextMaxLine variant="body2" line={1} sx={{ fontWeight: 'fontWeightMedium' }}>
                        {product.ProductName}
                    </TextMaxLine>
                    <Typography variant="subtitle2">{product.isHotel === 0 ? fCurrency(product.ProductPrice ? product.ProductPrice : "0") : `RM ${product.ProductPrice}`}</Typography>
                    <Stack spacing={0.5} direction="row" alignItems="center">
                        <Rating size="small" sx={{ '& svg': { height: 12, width: 12 } }} value={product.ProductRating} readOnly precision={0.5} />
                        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                            ({product.ProductReviewCount} reviews)
                        </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" flexGrow={1}>
                        <IconButton onClick={() => handleNavigate()}>
                            <Iconify icon="carbon:shopping-cart-plus" />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteWishlist(product.UserWishlistID)} >
                            <Iconify icon="material-symbols:delete-outline" />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
        </Link >
    );
}

EcommerceWishlistItemCard.propTypes = {
    product: PropTypes.shape({
        isHotel: PropTypes.number,
        ProductID: PropTypes.number,
        ProductImage: PropTypes.string,
        ProductName: PropTypes.string,
        ProductPrice: PropTypes.string,
        ProductPromotion: PropTypes.object,
        ProductVariationValue: PropTypes.string,
        ProductStock: PropTypes.number,
        ProductQuantity: PropTypes.number,
        MerchantShopProductName: PropTypes.string,
        MerchantID: PropTypes.number,
        UserWishlistID: PropTypes.number,
        ProductRating: PropTypes.number,
        ProductReviewCount: PropTypes.number,
    }),
    wishlist: PropTypes.bool,
    handleDeleteWishlist: PropTypes.func,
    sx: PropTypes.object,
};
