import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  Card,
  Chip,
  IconButton,
  Unstable_Grid2 as Grid,
  Checkbox,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import {
  EcommerceCheckoutOrderSummary,
  EcommerceCheckoutBillingView,
  EcommerceCheckOutChooseBIllingAddress,
} from '../checkout';

import AddressItem from '../account/address/AddressItem';

// ----------------------------------------------------------------------

export default function EcommerceCheckoutView({
  handlePrevious,
  handleNext,
  cartProduct,
  summary,
  country,
  address,
  selectedAddress,
  onCreateBilling,
  isCalculateDeliveryFees,
  cartListing,
  orderID,
  setHotelInfoDialog,
  handleCheckPromo,
  handlePromoCode,
  promoCode,
  validPromoData,
  customerBillingData,
  setCustomerBillingData,
  cartType,
  currentAddress,
  selectedAddressID,
  setSelectedAddressID,
  CallSelectedAddressID,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(false);

  const EcommerceCheckoutSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(EcommerceCheckoutSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const getShippingLabel = () => {
    let label = 2;
    switch (cartType) {
      case 'hotelStep':
        label = 3;
        break;

      case 'combinationStep':
        label = 4;
        break;

      default:
        break;
    }
    return label;
  };

  const CallBack = (data) => {
    setOpenForm(data);
  };

  // const CallSelectedAddressID = (data) => {
  //   setSelectedAddressID(data)
  // }

  return (
    // <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
    <>
      <Grid container spacing={{ xs: 3, md: 8 }}>
        <Grid xs={12} md={8}>
          {cartType !== 'hotelStep' ? (
            <Stack spacing={5} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
              <div>
                <Button
                  onClick={handlePrevious}
                  color="inherit"
                  startIcon={<Iconify icon="carbon:chevron-left" />}
                  sx={{ mb: 3 }}
                >
                  {cartType !== 'hotelStep' && cartType !== 'eventStep'
                    ? 'Back to Cart'
                    : 'Back to Customer Information'}
                </Button>

                {cartType !== 'eventStep' && (
                  <>
                    <Stack direction="row" justifyContent="space-between">
                      <StepLabel
                        title="Shipping Address"
                        step={cartType === 'productStep' ? '1' : '3'}
                      />
                    </Stack>

                    {currentAddress.length > 0 &&
                      currentAddress.map((item, index) => (
                        <Card
                          key={index}
                          sx={{
                            p: 2,
                            mb: 3,
                            textAlign: 'left',
                          }}
                        >
                          <AddressItem
                            key={index}
                            address={item}
                            selectedAddressID={selectedAddressID}
                            onCreateBilling={() => {
                              setSelectedAddressID(item.UserAddressBookID);
                              onCreateBilling(item);
                            }}
                            setIsLoading={setIsLoading}
                            isloading={isLoading}
                            orderID={orderID}
                            viewOnly={!false}
                            handleCallBack={CallBack}
                            handleGetSelectedAddresID={CallSelectedAddressID}
                          />
                        </Card>
                      ))}
                  </>
                )}
              </div>
            </Stack>
          ) : (
            <Stack spacing={5} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
              <div>
                <Button
                  onClick={handlePrevious}
                  color="inherit"
                  startIcon={<Iconify icon="carbon:chevron-left" />}
                  sx={{ mb: 3 }}
                >
                  {cartType !== 'hotelStep' && cartType !== 'eventStep'
                    ? 'Back to Cart'
                    : 'Back to Customer Information'}
                </Button>
              </div>
            </Stack>
          )}

          <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' } }} justifyContent="space-between">
            <StepLabel title="Billing Details" step={String(getShippingLabel())} />
            <Stack pl={4} direction="row">
              <Checkbox
                label="Required Bill"
                checked={customerBillingData?.requiredBill}
                disabled={orderID !== ''}
                onChange={(e, value) =>
                  setCustomerBillingData({ ...customerBillingData, requiredBill: value })
                }
              />
              <Typography variant="subtitle2" mt={1.1}>
                {' '}
                Required Invoice{' '}
              </Typography>
            </Stack>
          </Stack>
          {customerBillingData.requiredBill ? (
            <EcommerceCheckoutBillingView
              cartType={cartType}
              orderID={orderID}
              isNewAddress
              currentAddress={customerBillingData}
              setCustomerBillingData={setCustomerBillingData}
              address={address}
              selectedAddressID={selectedAddressID}
            />
          ) : (
            <Typography pl={5} variant="subtitle2">
              No Invoice is required for this purchase
            </Typography>
          )}
        </Grid>

        <Grid xs={12} md={4}>
          <EcommerceCheckoutOrderSummary
            tax={summary.tax === 0 ? 0 : summary.tax}
            total={summary.subtotal + summary.shipping - summary.discount + summary.tax}
            subtotal={summary.subtotal === 0 ? 0 : summary.subtotal}
            shipping={summary.shipping === 0 ? 0 : summary.shipping}
            discount={summary.discount === 0 ? 0 : summary.discount}
            handleNext={handleNext}
            customerBillingData={customerBillingData}
            products={cartProduct}
            cartListing={cartListing}
            loading={isSubmitting}
            pageType={cartType === 'hotelStep' ? '' : 'delivery'}
            isCalculateDeliveryFees={isCalculateDeliveryFees}
            promoCode={promoCode}
            handleCheckPromo={handleCheckPromo}
            handlePromoCode={handlePromoCode}
            validPromoData={validPromoData}
            orderID={orderID}
            setHotelInfoDialog={setHotelInfoDialog}
          />
        </Grid>
      </Grid>
      {openForm && (
        <EcommerceCheckOutChooseBIllingAddress
          open={openForm}
          onClose={() => setOpenForm(false)}
          currentAddress={null}
          address={address}
          onCreateBilling={onCreateBilling}
          orderID={orderID}
          selectedAddress={selectedAddress}
          selectedAddressID={selectedAddressID}
          setSelectedAddressID={setSelectedAddressID}
          handleGetSelectedAddresID={CallSelectedAddressID}
        />
      )}
    </>

    // </FormProvider>
  );
}

EcommerceCheckoutView.propTypes = {
  cartProduct: PropTypes.array,
  cartListing: PropTypes.array,
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func,
  country: PropTypes.array,
  address: PropTypes.array,
  onCreateBilling: PropTypes.func,
  isCalculateDeliveryFees: PropTypes.bool,
  summary: PropTypes.object,
  handlePromoCode: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  promoCode: PropTypes.string,
  validPromoData: PropTypes.object,
  orderID: PropTypes.string,
  customerBillingData: PropTypes.object,
  setCustomerBillingData: PropTypes.func,
  cartType: PropTypes.string,
  setHotelInfoDialog: PropTypes.func,
  selectedAddress: PropTypes.object,
  currentAddress: PropTypes.array,
  selectedAddressID: PropTypes.number,
  setSelectedAddressID: PropTypes.func,
  CallSelectedAddressID: PropTypes.func,
};

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function StepLabel({ step, title }) {
  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 1, typography: 'h6' }}>
      <Box
        sx={{
          mr: 1.5,
          width: 28,
          height: 28,
          flexShrink: 0,
          display: 'flex',
          typography: 'h6',
          borderRadius: '50%',
          alignItems: 'center',
          bgcolor: 'primary.main',
          justifyContent: 'center',
          color: 'primary.contrastText',
        }}
      >
        {step}
      </Box>
      {title}
    </Stack>
  );
}

StepLabel.propTypes = {
  step: PropTypes.string,
  title: PropTypes.string,
};
