// @mui
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Pagination, Box } from '@mui/material';
//
import ReviewItem from './ReviewItem';

// ----------------------------------------------------------------------

export default function ReviewList({ reviews }) {
  const rowsPerPage = 2
  const [page, setPage] = useState(1);
  
  return (
    <Box sx={{ pt: 5 }}>
      {reviews
      .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
      .map((review) => (
        <ReviewItem
          key={review.ProductReviewID}
          name={review.Name}
          avatarUrl={review.avatarUrl}
          postedAt={review.CreatedDate}          
          duration={review.ProductReviewDuration}
          message={review.ProductReviewComment}
          rating={review.ProductReviewRating}
          reviewDetails={review.ProductReviewDetail !== "[]"? JSON.parse(review.ProductReviewDetail) : []}
          // helpful={review.helpful}
        />
      ))}
      {reviews.length > 0 && (
        <Pagination

          current={page}
          count={Math.ceil(reviews.length / rowsPerPage)}
          onChange={(e, value) => setPage(value)}

          color="primary"
          size="large"
          sx={{
            mt: 5,
            mb: 10,
            '& .MuiPagination-ul': {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </Box>
  );
}

ReviewList.propTypes = {
  reviews: PropTypes.array,
};
