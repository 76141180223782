import PropTypes from 'prop-types';
// @mui
import { MobileDatePicker } from '@mui/x-date-pickers';
import { InputAdornment, InputBase } from '@mui/material';
// components
import Iconify from 'src/components/iconify';

// import DateRangePicker, { useDateRangePicker } from '../../../../../components/date-range-picker';
// ----------------------------------------------------------------------

export default function TravelFilterTime({
  checkInDate,
  onChangeDepartureDay,
  checkOutDate,
  onChangeCheckOutDate,
  sx,
  isProduct,
}) {
  const getEndMinDate = () => {
    const selectedDate = checkInDate || new Date();
    const newDate = new Date(selectedDate);

    if (!isProduct) newDate.setDate(selectedDate.getDate() + 1);

    return newDate;
  };

  return (
    <>
      <MobileDatePicker
        value={checkInDate}
        onChange={onChangeDepartureDay}
        minDate={new Date()}
        slots={{
          textField: ({ inputProps, InputProps, ownerState, inputRef, error, ...inputOther }) => (
            <InputBase
              fullWidth
              {...InputProps}
              ref={InputProps?.ref}
              inputRef={inputRef}
              inputProps={{
                ...inputProps,
                ...inputOther,
                placeholder: 'Check in date',
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    width={24}
                    icon="carbon:calendar"
                    sx={{ color: 'text.disabled', mr: 1 }}
                  />
                </InputAdornment>
              }
              sx={{ height: 44, typography: 'subtitle1', color: 'inherit', ...sx }}
            />
          ),
        }}
        {...sx}
      />
      <MobileDatePicker
        value={checkOutDate}
        onChange={onChangeCheckOutDate}
        minDate={getEndMinDate()}
        slots={{
          textField: ({ inputProps, InputProps, ownerState, inputRef, error, ...inputOther }) => (
            <InputBase
              fullWidth
              {...InputProps}
              ref={InputProps?.ref}
              inputRef={inputRef}
              inputProps={{
                ...inputProps,
                ...inputOther,
                placeholder: 'Check out date',
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    width={24}
                    icon="carbon:calendar"
                    sx={{ color: 'text.disabled', mr: 1 }}
                  />
                </InputAdornment>
              }
              sx={{ height: 44, typography: 'subtitle1', color: 'inherit', ...sx }}
            />
          ),
        }}
        {...sx}
      />
    </>
  );
}

TravelFilterTime.propTypes = {
  checkInDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChangeDepartureDay: PropTypes.func,
  checkOutDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChangeCheckOutDate: PropTypes.func,
  sx: PropTypes.object,
  isProduct: PropTypes.bool,
};
