// @mui
import PropTypes from 'prop-types';
import moment from 'moment';
import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Stack, Typography, Checkbox, Grid, Button, Alert } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// components
import Scrollbar from 'src/components/scrollbar';
import {checkAccessCategory} from 'src/utils/helpers'
import { checkCartValidity, clearCartValidity } from 'src/redux/slices/order';

import { useDispatch, useSelector } from 'src/redux/store';
import { RefreshDialog } from 'src/sections/_travel/tour/components';

import EcommerceCartItemSide from './EcommerceCartItemSide';
import EmptyContent from '../../../pages/EmptyContent';
// assets
import EmptyContentImage from '../../../assets/illustrations/illustration_empty_content.svg';

// ----------------------------------------------------------------------

export default function EcommerceCartListSide({ merchantList, productCart }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [refreshDialog, setRefreshDialog] = useState(false);
  const { isCartValid } = useSelector((data) => data.order);
  const [subTotal, setSelectedSubtotal] = useState(0);
  const [isTicketContain, setIsTicketContain] = useState(false);

  useEffect(() => {
    if (isCartValid) {
      if (isCartValid?.isRequiredRefresh === 1) {
        setRefreshDialog(true);
      } else {
        handleProceedCart();
      }
    }
    return () => {
      dispatch(clearCartValidity());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartValid]);

  useEffect(() => {
    if (productCart && productCart.length > 0) {
      if (productCart.filter((x) => x.isEvent === 1).length > 0) setIsTicketContain(true);
      else setIsTicketContain(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCart]);

  const handleSelectedItem = (item, type) => {
    let filterProduct = [];

    if (type !== 'all') {
      const listing = [...selectedProduct];
      if (listing.length > 0) {
        const found = [];
        listing.forEach((x) => {
          if (x.UserCartID !== item.UserCartID) {
            filterProduct.push(x);
          } else {
            found.push(true);
          }
        });

        if (found.length === 0) {
          filterProduct.push(item);
        }
      } else {
        filterProduct.push(item);
      }
    } else {
      const listing = [...selectedProduct];
      if (listing.length !== item.length) {
        filterProduct = [...item];
      }
    }

    const isSelectedTicket = filterProduct.filter((x) => x.isEvent === 1).length > 0;
    let totalPrice = 0;
    filterProduct.map((x) => {
      if (x.ProductPromotion !== '[]') {
        const promoData = JSON.parse(x.ProductPromotion).filter(
          (y) => y.ProductVariationDetailID === x.ProductVariationDetailID
        );
        if (promoData.length > 0 && promoData[0].ProductStockAvailable >= x.ProductQuantity)
          totalPrice += promoData[0].PromotionPrice * x.ProductQuantity;
        else totalPrice += x.ProductPrice * x.ProductQuantity;
      } else if (x.ProductPromotion === '[]' && x.EventMerchandiseInd === 1 && isSelectedTicket) {
        totalPrice += x.DiscountedProductPrice ? x.DiscountedProductPrice * x.ProductQuantity : 0;
      } else {
        totalPrice += x.ProductPrice ? x.ProductPrice * x.ProductQuantity : 0;
      }
      return totalPrice;
    });
    setSelectedSubtotal(totalPrice);
    setSelectedProduct(filterProduct);
  };

  const handleAdjustSelectedQuantity = (UserCartID, data) => {
    const listing = [...selectedProduct];
    const ListingIndex = listing.findIndex((x) => x.UserCartID === UserCartID);
    if (ListingIndex !== -1) {
      listing[ListingIndex].ProductQuantity = data;

      const isSelectedTicket = listing.filter((x) => x.isEvent === 1).length > 0;
      let totalPrice = 0;
      listing.map((x) => {
        if (x.ProductPromotion !== '[]') {
          const promoData = JSON.parse(x.ProductPromotion).filter(
            (y) => y.ProductVariationDetailID === x.ProductVariationDetailID
          );
          if (promoData.length > 0 && promoData[0].ProductStockAvailable >= x.ProductQuantity)
            totalPrice += promoData[0].PromotionPrice * x.ProductQuantity;
          else totalPrice += x.ProductPrice * x.ProductQuantity;
        }
        if (x.ProductPromotion === '[]' && x.EventMerchandiseInd === 1 && isSelectedTicket) {
          totalPrice += x.DiscountedProductPrice ? x.DiscountedProductPrice * x.ProductQuantity : 0;
        } else {
          totalPrice += x.ProductPrice ? x.ProductPrice * x.ProductQuantity : 0;
        }

        return totalPrice;
      });
      setSelectedSubtotal(totalPrice);
      setSelectedProduct(listing);
    }
  };

  const processData = () => {
    const processedPromoData = [];
    const isSelectedTicket = selectedProduct.filter((x) => x.isEvent === 1).length > 0;
    selectedProduct.forEach((details) => {
      let productPrice = details.ProductPrice;
      if (details.EventMerchandiseInd && isSelectedTicket)
        productPrice = details.DiscountedProductPrice ? details.DiscountedProductPrice : 0;

      const oriPrice = productPrice;
      let promoPrice = productPrice;
      let discount = 'false';
      let purchaseLimit = details.ProductStock;
      let stockLimit = details.ProductStock;
      let promoID = 0;
      let promoTitle = '';
      const promotion = details.ProductPromotion !== '[]' ? details.ProductPromotion : '[]';

      if (details.ProductPromotion !== '[]') {
        const promoData = JSON.parse(details.ProductPromotion).filter(
          (x) => x.ProductVariationDetailID === details.ProductVariationDetailID
        );

        if (promoData.length > 0 && promoData[0].ProductStockAvailable >= details.ProductQuantity) {
          promoPrice = promoData[0].PromotionPrice;
          discount = promoData[0].ProductDiscount.toString();
          purchaseLimit = promoData[0].ProductPurchaselimit;
          stockLimit = promoData[0].ProductStockAvailable;
          promoID = promoData[0].PromotionID;
          promoTitle = promoData[0].PromotionTitle;
        }
      }

      processedPromoData.push({
        ...details,
        ProductPromotion: promotion,
        ProductPrice: promoPrice,
        OriProductPrice: oriPrice,
        Discount: discount,
        ProductPurchaselimit: purchaseLimit,
        ProductStockLimit: stockLimit,
        PromotionID: promoID,
        PromotionTitle: promoTitle,
        Remarks: '',
        PromoCode: '',
        PromoCodeID: 0,
        PromoCodeDiscount: 0,
        ACCESSCATEGORYID:checkAccessCategory(details.isHotel, details.isEvent) ,
      });
    });

    // const removeDuplicate = processedPromoData.filter((ele, ind) => ind === processedPromoData.findIndex(elem => elem.MerchantID === ele.MerchantID))

    return processedPromoData;
  };

  const handleCheckValidity = () => {
    // const processedPromoData = processData()
    // const filterPromoData = processedPromoData.filter((x) => x.PromotionID !== 0 && x.isEvent === 1)
    // const promoData = []
    // const ticketData = []
    // const ticketQuantity = []

    // if (filterPromoData.length > 0) {
    //   filterPromoData.forEach((x) => {
    //     promoData.push(x.PromotionID)
    //     ticketData.push(x.ProductVariationDetailID)
    //     ticketQuantity.push(x.ProductQuantity)
    //   })

    //   dispatch(checkCartValidity(promoData, ticketData, ticketQuantity))
    // }
    // else
    //   handleProceedCart()

    const promoData = [];
    const variationData = [];
    const productQuantity = [];
    const startDate = [];
    const endDate = [];
    const accessData = [];

    processData().forEach((x) => {
      promoData.push(x.PromotionID);
      variationData.push(x.ProductVariationDetailID);
      productQuantity.push(x.ProductQuantity);
      startDate.push(moment(x.CheckinDate).format('YYYY-MM-DD'));
      endDate.push(moment(x.CheckoutDate).format('YYYY-MM-DD'));
      accessData.push(x.ACCESSCATEGORYID);
    });
    dispatch(
      checkCartValidity(promoData, variationData, productQuantity, startDate, endDate, accessData)
    );
  };

  function handleProceedCart() {
    if (selectedProduct.length > 0) {
      const filterList = [];

      const processedPromoData = processData();
      const removeDuplicate = processedPromoData.filter(
        (ele, ind) =>
          ind === processedPromoData.findIndex((elem) => elem.MerchantID === ele.MerchantID)
      );

      if (removeDuplicate.length > 0) {
        removeDuplicate.forEach((x) => {
          const filterDetails = processedPromoData.filter((y) => y.MerchantID === x.MerchantID);
          filterList.push({
            ...x,
            cartList: filterDetails,
          });
        });
      }
      const obj = {
        merchantLising: filterList,
        cartLising: processedPromoData,
        orderData: null,
        isFromBookLah: false,
        isFromEmporiaApp: false,
      };
      navigate(paths.eCommerce.cart, { state: obj });
    }
  }

  const handleCheckCart = () => {
    let isDisabled = false;

    if (selectedProduct.length === 0) isDisabled = true;

    if (selectedProduct.filter((x) => x.ProductStock < x.ProductQuantity).length > 0)
      isDisabled = true;

    const filterData =
      selectedProduct.length > 0 ? selectedProduct.filter((x) => x.ProductPromotion !== '[]') : [];
    if (filterData.length > 0) {
      let isError = false;

      filterData.map((x) => {
        const filterListing = JSON.parse(x.ProductPromotion).filter(
          (y) => y.ProductVariationDetailID === x.ProductVariationDetailID
        );
        if (
          filterListing.length > 0 &&
          filterListing[0].ProductStockAvailable >= x.ProductQuantity
        ) {
          if (filterListing[0].ProductStockLimit < x.ProductQuantity) isError = true;

          if (filterListing[0].ProductPurchaselimit < x.ProductQuantity) isError = true;
        }
        return {};
      });
      isDisabled = isError;
    }

    if (selectedProduct.length > 9) isDisabled = true;

    if (selectedProduct.filter((x) => x.isHotel === 1).length > 0) {
      const hotelList = selectedProduct.filter((x) => x.isHotel === 1);
      if (
        hotelList.filter((x) => new Date(x.CheckinDate113).getDate() < new Date().getDate())
          .length > 0
      )
        isDisabled = true;
    }

    return isDisabled;
  };

  return (
    <>
      <Scrollbar>
        {merchantList.length > 0 ? (
          merchantList.map((merchant, index) => (
            <Fragment key={`index-${index}`}>
              {/* <Stack
                direction="row"
                alignItems="center"
                flexGrow={1}
                // onClick={() => merchant.isHotel === 0 ? navigate(paths.eCommerce.merchantProduct(merchant?.MerchantID)) : navigate(paths.hotel.lists)}
                // onClick={() => navigate(paths.eCommerce.products("Merchant", merchant?.MerchantID))}
                sx={{
                  py: 1,
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  cursor: "pointer"
                }}
              >
                <Iconify icon="mingcute:right-fill" />
                <Typography variant="subtitle2" >
                  {merchant.MerchantShopName}
                </Typography>
              </Stack> */}
              {merchant.cartList.map((product) => (
                <EcommerceCartItemSide
                  key={product.UserCartID}
                  product={product}
                  handleSelectedItem={handleSelectedItem}
                  selectedProduct={selectedProduct}
                  handleAdjustSelectedQuantity={handleAdjustSelectedQuantity}
                  isTicketContain={isTicketContain}
                />
              ))}
            </Fragment>
          ))
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            textAlign="center"
            justifyContent="space-around"
          >
            <EmptyContent
              title="Selected cart is empty"
              description="Look like you did't select items in your shopping cart."
              // img='../../../assets/illustrations/illustration_empty_content.svg'
              img={EmptyContentImage}
            />
          </Stack>
        )}
        {selectedProduct.length > 9 && (
          <Alert severity="error" size="small" sx={{ width: '100%' }}>
            {' '}
            Maximum 9 item per transaction allow
          </Alert>
        )}
      </Scrollbar>

      <Stack
        mt={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        display="flex"
        sx={{
          bottom: 0,
          position: 'fixed',
          backgroundColor: 'white',
          width: '50%',
        }}
      >
        <Grid container pty={1} alignItems="center" pb={0.8} pt={1.0}>
          <Checkbox
            onClick={() => handleSelectedItem(productCart, 'all')}
            checked={productCart.length !== 0 && selectedProduct.length === productCart.length}
            disabled={productCart.length === 0}
          />
          <Typography>All</Typography>
          <Stack sx={{ right: 15, position: 'fixed' }}>
            <Button
              variant="contained"
              onClick={() => handleCheckValidity()}
              disabled={handleCheckCart()}
            >
              Checkout ( {subTotal === 0 ? subTotal : `RM ${subTotal.toFixed(2)}`} )
            </Button>
          </Stack>
        </Grid>
      </Stack>

      <RefreshDialog refreshDialog={refreshDialog} isToEventPage={false} setRefresh={setRefreshDialog}/>
    </>
  );
}

EcommerceCartListSide.propTypes = {
  merchantList: PropTypes.array,
  productCart: PropTypes.array,
};
