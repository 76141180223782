// @mui
import { Stack, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';
import ReviewProgressItem from './ReviewProgressItem';

// ----------------------------------------------------------------------

export default function ReviewProgress({ ratingData, ...other}) {
  const totals = ratingData.map((rating) => rating.number).reduce(
    (accumulator, curr) => accumulator + curr
  );

  return (
    <RadioGroup>
      <Stack spacing={2} {...other}>
        {ratingData.map((rating, index) => (
          <ReviewProgressItem key={rating.value} rating={rating} index={index} totals={totals} />
        ))}
      </Stack>
    </RadioGroup>
  );
}


ReviewProgress.propTypes = {
  ratingData: PropTypes.array,
};
