import PropTypes from 'prop-types';
import moment from 'moment';
// @mui
import { Stack, TextField, IconButton, Typography, Grid, Box, Tooltip } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import Logo from 'src/assets/abbaparadise.png';
// utils
import { fCurrency } from 'src/utils/formatNumber';
import Label from 'src/components/label';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import TextMaxLine from 'src/components/text-max-line';
import { strings } from 'src/auth';

// ----------------------------------------------------------------------

// const CryptoJS = require("crypto-js");

export default function EcommerceCartItem({ product, wishlist, setHotelInfoDialog, parsedData }) {
  const navigate = useNavigate();

  const isSmUp = useResponsive('up', 'sm');

  const handleNavigate = (item) => {
    const { ProductID, isHotel, isTrip } = item;

    if (isHotel === 1) {
      navigate(paths.hotel.list(ProductID));
    }
    // else if (isTrip === 1) {
    //   const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(parsedData), strings.auth);
    //   const convertedText = encodeURIComponent(ciphertext);
    //   navigate(paths.tripPackage.package(convertedText));
    // }
    else {
      // navigate(paths.eCommerce.product(ProductID))
    }
  };

  const isInvalidDate =
    product &&
    product.CheckinDate113 &&
    moment(product.CheckinDate113).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD');

  return (
    <Stack
      sx={{
        py: 2,
        // minWidth: 720,
        overflow: 'hidden',
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
      key={product.UserCartID}
    >
      <Stack direction="row" alignItems="flex-start">
        <Grid container>
          <Grid item sm={8} xs={12} sx={{ display: { xs: 'flex', sm: 'block' } }}>
            <Stack sx={{ flexDirection: 'row' }} alignItems="flex-start" flexGrow={1}>
              <Grid item xs={3.5} sm={2.5}>
                <Image
                  src={product?.ProductImage || Logo}
                  onClick={() => handleNavigate(product)}
                  sx={{
                    width: 80,
                    height: 80,
                    flexShrink: 0,
                    borderRadius: 1.5,
                    bgcolor: 'background.neutral',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Stack spacing={1}>
                  <Stack direction="row" className="col-sm-12">
                    <TextMaxLine
                      line={2}
                      variant="subtitle2"
                      pr={1}
                      sx={{
                        color: isInvalidDate && 'grey',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleNavigate(product)}
                    >
                      {product.ProductName}
                    </TextMaxLine>
                    {product.isHotel === 1 && (
                      <Tooltip
                        title={`Click Me For More ${product.PropertyType} Information`}
                        placement="top-start"
                        onClick={() => setHotelInfoDialog(product)}
                      >
                        <Iconify icon="mdi:information-outline" style={{ color: '#FF5630' }} />
                      </Tooltip>
                    )}
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      variant="caption"
                      sx={{ display: { xs: 'contents', sm: 'none', color: 'grey', border: 0 } }}
                    >
                      Variation:{' '}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary', border: 0 }}>
                      {product.ProductVariationValue}
                      {product.Discount !== 'false' && (
                        <Label color="error" sx={{ mb: 0, ml: { xs: 0, sm: 3 } }}>
                          {' '}
                          {(product.Discount !== 'false'
                            ? parseFloat(product.Discount)
                            : 0
                          ).toFixed(2)}{' '}
                          % OFF{' '}
                        </Label>
                      )}
                    </Typography>
                  </Stack>

                  {product.EventMerchandiseInd === 1 && (
                    <Stack direction="row">
                      <Iconify icon="ph:warning-fill" width={18} sx={{ mr: 1, color: 'red' }} />
                      <Typography variant="caption" sx={{ color: '#d53600' }}>
                        Remark : Kindly note that merchandise purchases can only be collected on the
                        actual event day
                      </Typography>
                    </Stack>
                  )}
                  {isInvalidDate && (
                    <Stack direction="row">
                      <Iconify icon="ph:warning-fill" width={18} sx={{ mr: 1, color: 'red' }} />
                      <Typography variant="body2" sx={{ color: 'red', fontSize: '12px' }}>
                        Exceed Current Date
                      </Typography>
                    </Stack>
                  )}
                  {product.isHotel === 1 ? (
                    <>
                      <Stack direction="row">
                        <Iconify icon="uil:calender" width={16} sx={{ mr: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', fontSize: '12px' }}
                        >
                          {' '}
                          {product.CheckinDate113} - {product.PropertyTypeID !== 5 ?  product.CheckoutDate113 : product.CheckoutHallDate113} <br /> ({' '}
                          {product.NumberOfNight } { product.PropertyTypeID !== 5 ? "night" : "day"} ){' '}
                        </Typography>
                      </Stack>
                      {product.PropertyTypeID !== 5 &&
                        <Stack direction="row">
                          <Iconify icon="mdi:guest-room" width={16} sx={{ mr: 1 }} />
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', fontSize: '12px' }}
                          >
                            {' '}
                            {product.RoomType} Room{' '}
                          </Typography>
                        </Stack>
                      }

                      <Stack direction="row">
                        <Iconify icon="raphael:parent" width={16} sx={{ mr: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', fontSize: '12px' }}
                        >
                          {' '}
                          {product.MaxAdultPax} adult{' '}
                          {product.MaxChildPax > 0 && `, ${product.MaxChildPax} child`}{' '}
                        </Typography>
                      </Stack>
                    </>
                  ) : (
                      <Stack direction="row">
                      <Iconify icon="uil:calender" width={16} sx={{ mr: 1 }} />
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', fontSize: '12px' }}
                      >
                        {' '}
                        {product.CheckinDate113} - {product.CheckoutDate113} <br />{' '}
                      </Typography>
                    </Stack>
                  )}

                  {product.Discount !== 'false' &&
                    product.ProductQuantity > product.ProductPurchaselimit && (
                      <Typography variant="subtitle2" color="red">
                        This promotion allow only {product.ProductPurchaselimit} item per
                        transaction
                      </Typography>
                    )}

                  {product.Discount !== 'false' &&
                    product.ProductQuantity > product.ProductStockLimit && (
                      <Typography variant="subtitle2" color="red">
                        Only {product.ProductStockLimit} Promotion item left{' '}
                      </Typography>
                    )}

                  {!isSmUp && (
                    <Grid>
                      <Stack
                        sx={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="subtitle2">x{product.ProductQuantity}</Typography>
                        <Stack sx={{ width: { sm: 120 }, typography: 'subtitle2' }}>
                          {' '}
                          {`RM ${parseFloat(
                            product.ProductPrice ? product.ProductPrice : '0'
                          ).toFixed(2)}`}
                          {product.Discount !== 'false' && (
                            <Box
                              component="span"
                              sx={{
                                color: 'text.disabled',
                                textDecoration: 'line-through',
                                fontWeight: 'fontWeightMedium',
                              }}
                            >
                              {fCurrency(product.OriProductPrice ? product.OriProductPrice : '0')}
                            </Box>
                          )}
                        </Stack>

                        {wishlist && (
                          <IconButton>
                            <Iconify icon="carbon:shopping-cart-plus" />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  )}
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          {isSmUp && (
            <>
              <Grid item sm={2} xs={2}>
                <Stack sx={{ width: 120 }}>
                  <Typography variant="subtitle2">{product.ProductQuantity}</Typography>
                </Stack>
              </Grid>
              <Grid item sm={2} xs={4}>
                <Stack sx={{ width: 120, typography: 'subtitle2', color: isInvalidDate && 'grey' }}>
                  {' '}
                  {fCurrency(product.ProductPrice ? product.ProductPrice : '0')}{' '}
                </Stack>
                {product.Discount !== 'false' && (
                  <Box
                    component="span"
                    sx={{
                      color: 'text.disabled',
                      textDecoration: 'line-through',
                      fontWeight: 'fontWeightMedium',
                    }}
                  >
                    {fCurrency(product.OriProductPrice ? product.OriProductPrice : '0')}
                  </Box>
                )}

                {wishlist && (
                  <IconButton>
                    <Iconify icon="carbon:shopping-cart-plus" />
                  </IconButton>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
      {product.ProductQuantity > product.ProductStock && (
        <Typography variant="subtitle2" color="red">
          Insufficient stock for you purchase
        </Typography>
      )}
    </Stack>
  );
}

EcommerceCartItem.propTypes = {
  product: PropTypes.shape({
    ProductVariationDetailID: PropTypes.number,
    EventMerchandiseInd: PropTypes.number,
    UserCartID: PropTypes.number,
    ProductID: PropTypes.number,
    ProductImage: PropTypes.string,
    ProductName: PropTypes.string,
    ProductPrice: PropTypes.number,
    OriProductPrice: PropTypes.number,
    ProductVariationValue: PropTypes.string,
    ProductStock: PropTypes.number,
    ProductQuantity: PropTypes.number,
    MerchantShopName: PropTypes.string,
    MerchantID: PropTypes.number,
    Discount: PropTypes.string,
    ProductPurchaselimit: PropTypes.number,
    ProductStockLimit: PropTypes.number,
    isHotel: PropTypes.number,
    NumberOfNight: PropTypes.number,
    CheckinDate113: PropTypes.string,
    CheckoutDate113: PropTypes.string,
    MaxChildPax: PropTypes.number,
    MaxAdultPax: PropTypes.number,
    RoomType: PropTypes.string,
    PropertyType: PropTypes.string,
    PropertyTypeID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    CheckoutHallDate113: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isTrip: PropTypes.number,
  }),
  wishlist: PropTypes.bool,
  setHotelInfoDialog: PropTypes.func,
  parsedData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
