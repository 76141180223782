import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { IconButton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
//
import Iconify from '../iconify';
import SingleFilePreview from './preview/SingleFilePreview';
import FileThumbnail from '../file-thumbnail/FileThumbnail';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: 'flex',
  flexShrink: 0,
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  color: theme.palette.text.disabled,
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  backgroundColor: alpha(theme.palette.grey[500], 0.08),
  '&:hover': {
    opacity: 0.72,
  },
}));

// ----------------------------------------------------------------------

UploadBox.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.node,
  helperText: PropTypes.node,
  onDelete: PropTypes.func,
};

export default function UploadBox({ file, placeholder, error, disabled, sx, helperText, onDelete, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled,
    ...other,
  });

  const hasFile = !!file;

  const isError = isDragReject || error;

  return (
    <StyledDropZone
      {...getRootProps()}
      sx={{
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(isError && {
          color: 'error.main',
          bgcolor: 'error.lighter',
          borderColor: 'error.light',
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
        ...sx,
      }}
    >
      <input {...getInputProps()} />

      {hasFile ?
        <>
          <FileThumbnail
            tooltip
            file={file}
            sx={{ position: 'absolute' }}
            imgSx={{ position: 'absolute' }}
          />

          {onDelete && (
            <IconButton
              size="small"
              onClick={() => onDelete(file)}
              sx={{
                position: 'absolute',
                color: (theme) => alpha(theme.palette.common.white, 0.72),
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                },
              }}
            >
              <Iconify icon="eva:close-fill" width={16} />
            </IconButton>
          )}
        </>
        :
        <Iconify icon="eva:cloud-upload-fill" width={28} />
      }

      {helperText && helperText}
    </StyledDropZone>
  );
}
