import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
// components
import FormProvider, { RHFTextField, RHFSelect } from 'src/components/hook-form';
import Notification from 'src/components/Notification/Notification';
import { addNewAddress, updateUserAddress, clearUserAddressAction } from 'src/redux/slices/profile';
// import { getState, getCountry } from 'src/redux/slices/general';
import { useDispatch, useSelector } from 'src/redux/store';
import { useAuthContext } from 'src/auth/useAuthContext';
import LoadingScreen from 'src/components/loading-screen';

// ----------------------------------------------------------------------
export default function AddressNewEditForm({ onClose, currentAddress, isNewAddress, ...other }) {
  const { addressAction, } = useSelector((state) => state.profile);

  const { states, country } = useSelector((state) => state.general);

  const dispatch = useDispatch();

  const { user } = useAuthContext();

  const [notification, setNotification] = useState({
    open: false,
    msg: "",
    type: "success",
  });

  const [STATE_OPTIONS, setStateOption] = useState([]);
  const [CITY_OPTIONS, setCityOption] = useState([]);

  const defaultValues = {
    recipient: currentAddress?.UserAddressName || "",
    email: currentAddress?.UserEmail || "",
    contact: currentAddress?.UserContactNo || "",
    addressLine1: currentAddress?.UserAddressLine1 || "",
    addressLine2: currentAddress?.UserAddressLine2 || "",
    postcode: currentAddress?.UserPoscode || "",
    state: currentAddress?.UserState || 7,
    city: currentAddress?.UserCity || "",
    country: currentAddress?.CountryID || 148,
  }


  useEffect(() => {
    if (currentAddress) {
      let selectedState = currentAddress?.UserState || 7
      setValue("city", currentAddress.UserCity)
      if (selectedState && states) {
        states.filter((x) => x.State.toUpperCase() === currentAddress.UserState.toUpperCase()).map((y) => {
          selectedState = y.StateID
          setValue("state", y.StateID)
          return {}
        })
      }
    }
    
    if (states && states.length > 0) {
      const stateData = []
      const cityData = []
      states.map((x) => stateData.push({ value: x.StateID, label: x.State, alias: x.StateAlias }))

      states.map((x) =>
        cityData.push({
          ...x,
          CityDetail: x.CityDetail && JSON.parse((x.CityDetail))
        } )
      )
      setCityOption(cityData)
      setStateOption(stateData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, currentAddress]);


  const NewReviewSchema = Yup.object().shape({
    recipient: Yup.string().required('Recipient Name is required'),
    email: Yup.string().required('Email Address is required').email("Email Address must be valid"),
    contact: Yup.string().required('Recipient Contact is required'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    addressLine2: Yup.string().required('Address Line 2 is required'),
    postcode: Yup.string().required('Postcode is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    country: Yup.number().required('Country is required'),
  });


  const methods = useForm({
    resolver: yupResolver(NewReviewSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const UserID = 1
  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      
      const stateData = STATE_OPTIONS.filter((x) => x.value === Number(data.state))[0].label

      if (isNewAddress === true) {
        dispatch(addNewAddress(data.recipient, UserID, data.contact, data.email, data.addressLine1, data.addressLine2, data.postcode, stateData, data.city, data.country))
      } else {
        dispatch(updateUserAddress(currentAddress?.UserAddressBookID, data.recipient, UserID, data.contact, data.email, data.addressLine1, data.addressLine2, data.postcode, stateData, data.city, data.country))
      }

      reset();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getSelectedStateCity = () => {
    const stateValue = getValues("state")
    const cityValue = []
    CITY_OPTIONS.filter((x)=> x.StateID === Number(stateValue)).map((y)=> cityValue.push(y.CityDetail) )
    return cityValue
  }

  if (!states) return <LoadingScreen />;

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} {...other}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ typography: 'h4', pb: 3 }}>{isNewAddress ? "Add New Address" : "Edit Address"}</DialogTitle>

        <DialogContent sx={{ py: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFTextField name="recipient" label="Recipient Name *" />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name="email" label="Email Address *" />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name="contact" label="Contact Number *" />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField name="addressLine1" label="Address Line 1 *" />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField name="addressLine2" label="Address Line 2 *" />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name="postcode" label="Postcode/ ZIP *" />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFSelect native name="state" label="State *">
                {STATE_OPTIONS && STATE_OPTIONS.length > 0 && STATE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value} >
                    {option.label}
                  </option>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFSelect native name="city" label="Town / City *">
                {getSelectedStateCity().map((option) => (
                  option.map((y) =>
                    <option key={y.City} value={y.City}>
                      {y.City}
                    </option>
                  )
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFSelect native name="country" label="Country *">
                {country && country.map((option) => (
                  <option key={option.CountryId} value={option.CountryId} >
                    {option.CountryName}
                  </option>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="inherit">
            Cancel
          </Button>

          <LoadingButton color="inherit" type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
      <Notification notification={notification} setNotification={setNotification} />
    </Dialog>
  );
}

AddressNewEditForm.propTypes = {
  onClose: PropTypes.func,
  isNewAddress: PropTypes.bool,
  currentAddress: PropTypes.object,
};
