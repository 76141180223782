// @mui
import { Stack, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function EcommerceProductDescriptionSkeleton() {
  return (
    <>

      <Stack spacing={1} sx={{ mb: 3, mt: 10 }}>
        <Skeleton variant="rounded" height={36} width="50%" />
      </Stack>
      <Stack spacing={1} sx={{ mb: 2 }}>
        <Skeleton variant="rounded" height={36} width="90%" pt={1}/>
        <Skeleton variant="rounded" height={36} width="100%" pt={1}/>
        <Skeleton variant="rounded" height={36} width="100%" pt={1}/>
      </Stack>

    </>
  );
}
