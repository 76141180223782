import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { Container, Typography } from '@mui/material';
import Notification from 'src/components/Notification/Notification';
import { useDispatch, useSelector } from '../../../redux/store';

import { deleteProductWishlist, clearProductWishlistAction } from '../../../redux/slices/product';
//

import { EcommerceCartList } from '../cart';

// ----------------------------------------------------------------------

EcommerceWishlistView.propTypes = {
  headerRequired: PropTypes.string,
};

export default function EcommerceWishlistView({ headerRequired }) {
  const { productWishlist, productWishlistAction } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({ open: false, msg: "", type: "", });

  const vertical = 'top'
  const horizontal = 'right'

  useEffect(() => {
    if (productWishlistAction.length > 0) {
      dispatch(clearProductWishlistAction())
      setNotification({ open: true, msg: "Removed from Wishlist", type: "success" })
    }
  }, [productWishlistAction, dispatch]);

  const handleDeleteWishlist = (data) => dispatch(deleteProductWishlist(data))

  const handleClose = () => { setNotification({ open: false, msg: "", type: "" }) }

  return (
    <Container
      sx={{
        overflow: headerRequired !== 'false' && 'hidden',
        pt: headerRequired !== 'false' && 5,
      }}
    >
      <Typography variant={headerRequired !== 'false' ? 'h3' : 'h5'} sx={{ mb: headerRequired !== 'false' ? 5 : 3 }}>
        Wishlist
      </Typography>

      <EcommerceCartList wishlist products={productWishlist} handleDeleteWishlist={handleDeleteWishlist} />

      <Notification notification={notification} setNotification={setNotification} />
    </Container>
  );
}


