/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Label from 'src/components/label';
import { fCurrency } from 'src/utils/formatNumber';
import Logo from 'src/assets/abbaparadise.png';
// @mui
import { Stack, Typography, LinearProgress, Link, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
// routes
import { paths } from 'src/routes/paths';
// components
import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';
//
import { ProductPricing, ProductCustomizeOptionPicker } from '../../components';
// ----------------------------------------------------------------------

export default function EcommerceProductItemHot({ product, type, hotProduct = false, sx, isMerchandise, handleVariationChange, selectedVariation }) {

  const handleSelectVariation = (data) => {
    handleVariationChange(Number(data.target.value), product.ProductID)
  }

  const handleProductWithoutVariation = () => {
    if (product.ProductID === selectedVariation.product)
      handleVariationChange(0, 0)
    else if (product.ProductVariation && product.ProductVariation !== "[]") {
      const VariationID = JSON.parse(product.ProductVariation)[0].ProductVariationDetailID
      handleVariationChange(Number(VariationID), product.ProductID)
    }
  }


  const itemLayout = () => (
    <Stack
      spacing={2}
      sx={{
        p: 2,
        borderRadius: 2,
        bgcolor: isMerchandise && selectedVariation.product === product.ProductID ? "#F5F5F5" : 'background.default',
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
        transition: (theme) =>
          theme.transitions.create('background-color', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.shortest,
          }),
        '&:hover': {
          bgcolor: 'background.neutral',
        },
        ...sx,
      }}
    >
      <Stack alignItems="center" sx={{ flexDirection: { xs: "row", sm: "column" } }}
      >
        <Image
          src={product.ProductImage ? product.ProductImage : Logo}
          sx={{
            mb: 2,
            width: { xs: 80, sm: 128 },
            height: { xs: 80, sm: 128 },
            flexShrink: 0,
            borderRadius: 1.5,
            bgcolor: 'background.neutral',
          }}
        />

        <Stack spacing={0.5} sx={{ pl: 2 }}>
          <TextMaxLine variant="body2" line={1} sx={{ fontWeight: 'fontWeightMedium' }}>
            {product.ProductName}
          </TextMaxLine>
          {
            hotProduct ?
              <>
                <Label color="error" sx={{ ml: 2, mt: 0.5 }}> {product.ProductDiscount} % OFF   </Label>
                <Stack direction="row">
                  <Typography sx={{ ...(hotProduct && { color: 'error.main', typography: 'subtitle2' }), }}> {fCurrency(Number(product.PromotionPrice))} </Typography>
                  <Box
                    component="span"
                    sx={{
                      ml: 1,
                      color: 'text.disabled',
                      textDecoration: 'line-through',
                      fontWeight: 'fontWeightMedium',
                      typography: 'subtitle2'
                    }}
                  >
                    {fCurrency(product.PromotionPrice + product.DiscountPrice)}
                  </Box>
                </Stack>
              </> :
              <Stack direction="row">
                {
                  !isMerchandise ?
                    Number(product.ProductPrice) ?
                      <Typography sx={{ ...({ typography: 'subtitle2' }), }}> {fCurrency(Number(product.ProductPrice))} </Typography> :
                      <Typography sx={{ ...({ typography: 'subtitle2' }), }} > RM {product.ProductPrice} </Typography>
                    :
                    <ProductPricing price={Number(product.DiscountedProductPrice ? product.DiscountedProductPrice : "0")} priceSale={Number(product.ProductPrice ? product.ProductPrice : "0")} discount={10} isDiscount percent={false} sx={{ typography: 'subtitle2' }} />
                 }
              </Stack>
          }
          {hotProduct && (
            <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
              <LinearProgress
                color="inherit"
                variant="determinate"
                value={(product.ProductStockSold / product.ProductStockAvailable) * 100}
                sx={{ width: 1 }}
              />

              <Typography
                variant="caption"
                sx={{ flexShrink: 0, color: 'text.disabled' }}
              >{`🔥 ${product.ProductStockSold} Sold`}</Typography>
            </Stack>
          )}
        </Stack>
        {
          isMerchandise && product.ProductVariation && product.ProductVariation !== "[]" && JSON.parse(product.ProductVariation)[0].ProductVariation !== "None" && JSON.parse(product.ProductVariation)[0].ProductVariation !== null &&
          <Stack spacing={2}>
            <ProductCustomizeOptionPicker value={selectedVariation.variation} onChange={handleSelectVariation} options={JSON.parse(product.ProductVariation)} optionLabel='ProductVariationValue' optionValue='ProductVariationDetailID' optionStock='ProductStockAmount' />
          </Stack>
        }
      </Stack>
    </Stack>
  )

  return (

    !isMerchandise ?
      (
        <Link component={RouterLink} to={paths.eCommerce.product(product.ProductID)} color="inherit" underline="none">
          {
          itemLayout()
          }
        </Link>
      )
      :
      (
        <Stack onClick={() => handleProductWithoutVariation()}  >
          {itemLayout()}
        </Stack>
      )

  );
}

EcommerceProductItemHot.propTypes = {
  hotProduct: PropTypes.bool,
  product: PropTypes.object,
  sx: PropTypes.object,
  type: PropTypes.string,
  isMerchandise: PropTypes.bool,
  handleVariationChange: PropTypes.func,
  selectedVariation: PropTypes.number,
};
