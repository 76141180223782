import PropTypes from 'prop-types';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Button, Divider } from '@mui/material';
import { alpha } from '@mui/material/styles';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// paths
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
//
import { TravelFilterTime, TravelFilterGuests, TravelFilterLocation } from './components';

import { useDispatch, useSelector } from '../../../../redux/store';
import { clearProduct } from '../../../../redux/slices/product';
import { setUserCheckInDate, setUserCheckOutDate } from '../../../../redux/slices/general';

// ----------------------------------------------------------------------

export default function ProductFilter({ sx, pullProductData,staticTop = 100, ...other }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'md');

  const { userCheckOutDate, userCheckInDate } = useSelector((data) => data.general);
  const [checkInDate, setCheckInDate] = useState(userCheckInDate || new Date());
  const [checkOutDate, setCheckOutDate] = useState(userCheckOutDate && userCheckOutDate >= userCheckInDate ? userCheckOutDate : userCheckInDate|| new Date());

  const [isSticky, setIsSticky] = useState(false);

  const sticky = isSticky && isDesktop;

  const handleScroll = () => {
    if (window.scrollY > staticTop) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(
    () => {
      window.addEventListener('scroll', handleScroll);

      if (!checkOutDate) {
        const newDate = new Date(checkInDate);
        // newDate.setDate(checkInDate.getDate() + 1);
        // setCheckOutDate(newDate);
        pullProductData(checkInDate, checkInDate);
      }
      if (checkInDate && checkOutDate) {
        pullProductData(checkInDate, checkOutDate);
      }

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (checkInDate) dispatch(setUserCheckInDate(checkInDate));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkInDate]
  );

  useEffect(
    () => {
      if (checkOutDate) dispatch(setUserCheckOutDate(checkOutDate));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkOutDate]
  );

  // const pullProductData = (checkIn, checkOut) => {
  //   dispatch(
  //     getProducts(
  //       'Merchant',
  //       0,
  //       0,
  //       999,
  //       1,
  //       moment(checkIn).format('YYYY-MM-DD'),
  //       moment(checkOut).format('YYYY-MM-DD')
  //     )
  //   );
  // };

  const handleChangeDepartureDay = (newValue) => {
    if (newValue >= checkOutDate) {
      const newDate = new Date(newValue);
      // newDate.setDate(newValue.getDate() + 1);
      setCheckOutDate(newDate);
    }
    setCheckInDate(newValue);
  };

  const handleChangeCheckOutDate = (newValue) => {
    if (newValue <= checkInDate) {
      const newDate = new Date(newValue);
      // newDate.setDate(newValue.getDate() - 1);
      setCheckInDate(newDate);
    }
    setCheckOutDate(newValue);
  };

  const handleNavigate = (checkIn, checkOut) => {
    dispatch(clearProduct())
    pullProductData(checkIn, checkOut)
  };

  return (
    <Stack
      spacing={2.5}
      alignItems={{ md: 'center' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        p: 2,
        borderRadius: 2,
        boxShadow: sticky ? '6' : 'none',
        ...sx,
      }}
      className={sticky ? 'is-sticky' : 'sidebar'}
      {...other}
    >
      <TravelFilterTime
        checkInDate={checkInDate}
        onChangeDepartureDay={handleChangeDepartureDay}
        checkOutDate={checkOutDate}
        onChangeCheckOutDate={handleChangeCheckOutDate}
        isProduct
      />

      {/* <Divider flexItem orientation="vertical" /> */}

      {/* <TravelFilterGuests
        guests={guests}
        onDecreaseGuests={handleDecreaseGuests}
        onIncrementGuests={handleIncrementGuests}
      /> */}

      <Button
        size="large"
        color="primary"
        variant="contained"
        sx={{
          px: 0,
          flexShrink: 0,
          minWidth: { xs: 1, md: 48 },
        }}
        onClick={() => handleNavigate(checkInDate, checkOutDate)}
      >
        <Iconify icon="carbon:search" />
      </Button>
    </Stack>
  );
}

ProductFilter.propTypes = {
  sx: PropTypes.object,
  staticTop: PropTypes.number,
  pullProductData: PropTypes.func,
};
