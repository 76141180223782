import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { Container } from '@mui/material';
//
import ReviewNewForm from '../components/ReviewNewForm';
import ReviewList from './ReviewList';
import ReviewSummary from './ReviewSummary';

// ----------------------------------------------------------------------

export default function ReviewEcommerce({ review, rating, ratingData }) {
  const [openForm, setOpenForm] = useState(false);
  return (
    <>
      <ReviewSummary
        ratingData={ratingData}
        ratingsNumber={rating}
        reviewsNumber={review.length}
        onOpenForm={() => setOpenForm(true)}
      />

      <Container>
        <ReviewList reviews={review} />
      </Container>

      <ReviewNewForm open={openForm} onClose={() => setOpenForm(false)} />
    </>
  );
}

ReviewEcommerce.propTypes = {
  review: PropTypes.array,
  rating: PropTypes.number,
  ratingData: PropTypes.array,
};