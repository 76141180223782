import { createSlice } from '@reduxjs/toolkit';
// import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
// import { setUser } from '../../auth/utils';

// ----------------------------------------------------------------------

// const url = ServerConfiguration.eventApiUrl;
const url = ServerConfiguration.testingServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  tripPackage: null,
  tripPackageDetail: null,
  iternary: null,
  markerDetails: null,
};

const slice = createSlice({
  name: 'tripPackage',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getTripPackageListingSuccess(state, action) {
      state.isLoading = false;
      state.tripPackage = action.payload;
    },

    getTripPackageDetailByIDSuccess(state, action) {
      state.isLoading = false;
      state.tripPackageDetail = action.payload;
    },

    clearTripPackageSuccess(state, action) {
      state.isLoading = false;
      state.tripPackage = null;
    },

    // GET ITERNARY
    getIternarySuccess(state, action) {
      state.isLoading = false;
      state.iternary = action.payload;
    },

    // GET ITERNARY
    clearIternarySuccess(state, action) {
      state.isLoading = false;
      state.iternary = null;
    },

    // GET ITERNARY
    getMarkerDetailsSuccess(state, action) {
      state.isLoading = false;
      state.markerDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTripPackageListing(packageId, productPage, page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}TripPackage_ItemListByID?TRIPPACKAGEID=${packageId}&PROJECTID=${PROJECTID}&PRODUCTPERPAGE=${productPage}&PAGE=${page}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getTripPackageListingSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getTripPackageListingSuccess(JSON.parse(data.ReturnData)));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function getTripPackageDetailByID(packageId) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await fetch(
//         `${url}TripPackage_ItemDetailListByID?TRIPPACKAGEID=${packageId}`
//       )
//       const json = await response.json();
//       const data = JSON.parse(json)[0];
//       if (data && data.ReturnVal === 1) {
//         dispatch(slice.actions.getTripPackageDetailByIDSuccess(JSON.parse(data.ReturnData)));
//       } else {
//         dispatch(slice.actions.getTripPackageDetailByIDSuccess(JSON.parse(data.ReturnData)));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }


// ----------------------------------------------------------------------

export function clearTripPackage() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearTripPackageSuccess(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIternary(packageId, day) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}TripPackage_ItemDetailListByDay?TRIPPACKAGEID=${packageId}&VISITDAY=${day}`);
      const json = await response.json();
      if (!!json && json?.Message) {
        dispatch(slice.actions.hasError("Something went wrong! Please try again later."));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getIternarySuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleariternary() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearIternarySuccess(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMarkerDetails(locationDetailsID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}TripPackage_ItemListByLocationDetailsID?LOCATIONDETAILSID=${locationDetailsID}`);
      
      const json = await response.json();
      if (!!json && json?.Message) {
        dispatch(slice.actions.hasError("Something went wrong! Please try again later."));
      } else {
        const data = JSON.parse(json)[0];
        if (data.ReturnVal === 1) {
          dispatch(slice.actions.getMarkerDetailsSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}