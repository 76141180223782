import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { Fab } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function FloatingFAQ() {
    const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isShow = window.scrollY > 400;
      setShow(isShow);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  if (!show) {
    return null;
  }

  return (
    <Fab
      size="medium"
      color="primary"
      aria-label="scroll back to top"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: (theme) => theme.zIndex.speedDial,
        color: 'common.white',
      }}
      onClick={()=>navigate(paths.faqs)}
    >
      <Iconify icon="wpf:faq" width={30} height={30} />
    </Fab>
  );
}