// import sum from 'lodash/sum';
// import uniq from 'lodash/uniq';
// import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;
const PROJECTID = ServerConfiguration.PROJECTID;

const initialState = {
  isLoading: false,
  error: null,
  products: null,
  product: null,
  productCart: [],
  productCartAction: [],
  eventTempCart: null,
  productCartByCartID: null,

  productWishlist: [],
  productWishlistAction: [],

  productReviewAction: [],
  productReview: [],
  landingProduct: [],
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalItems: 0,
  },
  reloadProduct: null,
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    clearProductListingSuccess(state, action) {
      state.isLoading = false;
      state.products = null;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getLandingProductSuccess(state, action) {
      state.isLoading = false;
      state.landingProduct = action.payload;
    },

    // CLEAR PRODUCT
    clearProduct(state, action) {
      state.isLoading = false;
      state.product = null;
    },

    // ------------------------ PRODUCT CART ----------------------------------------------

    getProductCartSuccess(state, action) {
      state.isLoading = false;
      state.productCart = action.payload;
    },

    getProductCartByCartIDSuccess(state, action) {
      state.isLoading = false;
      state.productCartByCartID = action.payload;
    },

    clearProductCartByCartIDSuccess(state, action) {
      state.isLoading = false;
      state.productCartByCartID = null;
    },
    getBooklahTempCartSuccess(state, action) {
      state.isLoading = false;
      state.eventTempCart = action.payload;
    },

    clearBooklahTempCartSuccess(state, action) {
      state.isLoading = false;
      state.eventTempCart = null;
    },

    productCartActionSuccess(state, action) {
      state.isLoading = false;
      state.productCartAction = action.payload;
    },

    clearProductCartActionSuccess(state, action) {
      state.isLoading = false;
      state.productCartAction = [];
    },

    // ------------------------ PRODUCT WISHLIST ----------------------------------------------

    productWishlistSuccess(state, action) {
      state.isLoading = false;
      state.productWishlist = action.payload;
    },

    productWishlistActionSuccess(state, action) {
      state.isLoading = false;
      state.productWishlistAction = action.payload;
    },

    clearProductWishlistActionSuccess(state, action) {
      state.isLoading = false;
      state.productWishlistAction = [];
    },

    // ------------------------ PRODUCT REVIEW ----------------------------------------------
    productReviewActionSuccess(state, action) {
      state.isLoading = false;
      state.productReviewAction = action.payload;
    },

    productReviewSuccess(state, action) {
      state.isLoading = false;
      state.productReview = action.payload;
    },

    clearProductReviewActionSuccess(state, action) {
      state.isLoading = false;
      state.productReviewAction = [];
    },

    // ----------------------------- PRODUCT RELOAD --------------------------------------
    getReloadProductSuccess(state, action) {
      state.isLoading = false;
      state.reloadProduct = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearProduct,
} = slice.actions;

// ----------------------------------------------------------------------
export function getProducts(type, typeValue, userId, noOfProducts, page, startDate, endDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_ItemListByTypeWithDate?TYPE=${type}&TYPEVALUE=${typeValue}&USERID=${userId}&PRODUCTPERPAGE=${noOfProducts}&PAGE=${page}&PROJECTID=${PROJECTID}&PLATFORMTYPE=android&STARTDATE=${startDate}&ENDDATE=${endDate}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data) {
        dispatch(slice.actions.getProductsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name, userId, startDate, endDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_ItemDetailByProductDate?ProductID=${name}&USERID=${userId}&PROJECTID=${PROJECTID}&PLATFORMTYPE=android&STARTDATE=${startDate}&ENDDATE=${endDate}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getProductSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- PRODUCT CART ---------------------------------------
export function viewProductCart(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await fetch(`${url}Product_ItemListInCartByUserID?USERID=${id}`);
      const response = await fetch(`${url}User_ItemListInCartByUserDate?USERID=${id}&PROJECTID=${PROJECTID}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getProductCartSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getProductCartSuccess([]));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewUserCartByCartID(id, type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}User_ItemListInCartByCartID?CartIDList=${id}&TYPE=${type}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getProductCartByCartIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getProductCartByCartIDSuccess([]));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearProductCartByCartID() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearProductCartByCartIDSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewBooklahTempCart(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await fetch(`${url}Product_ItemListInCartByUserID?USERID=${id}`);
      const response = await fetch(`${url}User_ListTempItemByUserCartID?USERCARTID=${id}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getBooklahTempCartSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getBooklahTempCartSuccess([]));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearBooklahTempCart() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearBooklahTempCartSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProductCart(userId, productId, quantity, variationDetailId, promoCode, startDate, endDate, accessCategoryID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_AddCart?USERID=${userId}&PRODUCTID=${productId}&PRODUCTQUANTITY=${quantity}&PRODUCTVARIATIONDETAILID=${variationDetailId}&APPLYINGPROMOCODE=${promoCode}&STARTDATE=${startDate}&ENDDATE=${endDate}&ACCESSCATEGORYID=${accessCategoryID}`);

      // const response = await fetch(`${url}Product_AddProductCart?USERID=${userId}&PRODUCTID=${productId}&PRODUCTQUANTITY=${quantity}&PRODUCTVARIATIONDETAILID=${variationDetailId}&APPLYINGPROMOCODE=${promoCode}&STARTDATE=${startDate}&ENDDATE=${endDate}&ISHOTEL=${isHotel}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productCartActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProductCart(userId, userCartId, quantity, accessCategoryID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_UpdateCart?USERCARTID=${userCartId}&PRODUCTQUANTITY=${quantity}&ACCESSCATEGORYID=${accessCategoryID}`);
      //  const response = await fetch(`${url}Product_UpdateProductCart?USERCARTID=${userCartId}&PRODUCTQUANTITY=${quantity}&ISHOTEL=${isHotel}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productCartActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProductCart(Id, accessCategoryID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_DeleteCart?USERCARTID=${Id}&ACCESSCATEGORYID=${accessCategoryID}`);
      // const response = await fetch(`${url}Product_DeleteProductCart?USERCARTID=${Id}&ISHOTEL=${isHotel}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productCartActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearProductCartAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearProductCartActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- PRODUCT WISHLIST ---------------------------------------
export function viewProductWishlist(Id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_ItemListInWishlistByUserID?USERID=${Id}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productWishlistSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.productWishlistSuccess([]));
        // dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProductWishlist(userId, productId, isHotel, isEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_AddWishlist?USERID=${userId}&PRODUCTID=${productId}&ISHOTEL=${isHotel}&ISEVENT=${isEvent}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productWishlistActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProductWishlist(Id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_DeleteProductWishlist?USERWISHLISTID=${Id}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productWishlistActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearProductWishlistAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearProductWishlistActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- PRODUCT REVIEW ---------------------------------------
export function addProductReview(parentReviewId, productId, userId, reviewRating, reviewComment, replyParentId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_AddReview?PARENTPRODUCTREVIEWID=${parentReviewId}&PRODUCTID=${productId}&USERID=${userId}&PRODUCTREVIEWRATING=${reviewRating}&PRODUCTREVIEWCOMMENT=${reviewComment}&REPLYPARENTID=${replyParentId}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productReviewActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProductReviewByCategory(parentReviewId, productId, userId, reviewRating, reviewComment, replyParentId, accessCategoryID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_AddReviewByCategory?PARENTPRODUCTREVIEWID=${parentReviewId}&PRODUCTID=${productId}&USERID=${userId}&PRODUCTREVIEWRATING=${reviewRating}&PRODUCTREVIEWCOMMENT=${reviewComment}&REPLYPARENTID=${replyParentId}&ACCESSCATEGORY=${accessCategoryID}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productReviewActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function viewProductReviewByProductID(productId, parentReviewId,) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_ViewReviewByProductID?PRODUCTID=${productId}&PARENTPRODUCTREVIEWID=${parentReviewId}`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.productReviewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearProductReviewAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearProductReviewActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewLandingPageProductListing(merchantId, productPerPage, page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_ItemListInLandingPage?MERCHANTID=${merchantId}&PROJECTID=${PROJECTID}&PRODUCTPERPAGE=${productPerPage}&PAGE=${page}&PLATFORMTYPE=ecommerce`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getLandingProductSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearProductListing() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearProductListingSuccess());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- PRODUCT RELOAD ---------------------------------------
export function getReloadProduct(name, userId, startDate,) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_ItemDetailByProductAvailability?ProductID=${name}&USERID=${userId}&PROJECTID=${PROJECTID}&PLATFORMTYPE=android&SELECTEDDATE=${startDate}`);

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getReloadProductSuccess(JSON.parse(data.ReturnData)[0]));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
