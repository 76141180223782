import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Container, Unstable_Grid2 as Grid } from '@mui/material';
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import LoadingScreen from 'src/components/loading-screen';
import Footer from 'src/layouts/main/footer';
// routes
import { paths } from 'src/routes/paths';
import ReviewEcommerce from '../../review/e-commerce';
import { EcommerceHeader } from '../layout';
// eslint-disable-next-line import/no-cycle
import { EcommerceProductDetailsInfo, EcommerceProductDetailsCarousel, EcommerceProductDetailsDescription, EcommerceProductRecommendation, } from '../product/details';

import {
  EcommerceProductCarouselSkeleton,
  EcommerceProductDetailsSkeleton,
  EcommerceProductDescriptionSkeleton,
} from '../product/item';

import { useDispatch, useSelector } from '../../../redux/store';
import { getProduct, clearProduct } from '../../../redux/slices/product';

import {ProductFilter} from '../../_travel/tour/filters';
// ----------------------------------------------------------------------

EcommerceProductView.propTypes = {
  product: PropTypes.object,
  isQuickview: PropTypes.string,
  handlePullData: PropTypes.func,
};

export default function EcommerceProductView({ product, isQuickview, handlePullData }) {
  const dispatch = useDispatch();
  const [productsData, setProduct] = useState([]);

  const [ProductimageData, setProductimage] = useState([]);

  const [ProductreviewData, setProductreview] = useState([]);

  const [ProductspecificationData, setProductspecification] = useState([]);

  const [ProductvariationData, setProductvariation] = useState([]);

  const recommendedProducts = product ? JSON.parse(product.ProductRecommendation) : [];

  const [ratingData, setRatingData] = useState([
    { value: '5start', number: 0, label: 5 },
    { value: '4start', number: 0, label: 4 },
    { value: '3start', number: 0, label: 3 },
    { value: '2start', number: 0, label: 2 },
    { value: '1start', number: 0, label: 1 },
    { value: '0start', number: 0, label: 0 },
  ]);

  useEffect(() => {
    if (product && product !== undefined) {
      setProduct(product);

      const specificationsDisplay = [];
      JSON.parse(product.ProductSpecification).map((spec) =>
        specificationsDisplay.push({
          label: spec.ProductSpecification,
          value: spec.ProductSpecificationValue,
        })
      );
      setProductimage(
        product.ProductImages !== null && product.ProductImages !== '[]'
          ? JSON.parse(product.ProductImages)
          : []
      );
      setProductreview(
        product.ProductReview !== null && product.ProductReview !== '[]'
          ? JSON.parse(product.ProductReview)
          : []
      );
      setProductvariation(
        product.ProductVariation !== null && product.ProductVariation !== '[]'
          ? JSON.parse(product.ProductVariation)
          : []
      );
      setProductspecification(specificationsDisplay);
    }
  }, [product]);

  useEffect(() => {
    if (ProductreviewData && ProductreviewData.length > 0) {
      const filterData = [...ratingData];

      ratingData.forEach((x, index) => {
        let reviewNum = 0;
        reviewNum = ProductreviewData.filter((y) => y.ProductReviewRating === x.label).length;

        filterData[index].number = reviewNum;
      });
      setRatingData(filterData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProductreviewData]);

 

  return (
    <>
      {product ? (
        <Container sx={{ overflow: 'hidden' }}>
          <ProductFilter
            sx={{
              mt: 5,
              mb: { xs: 5, md: 10 },
            }}
            
         pullProductData={handlePullData}
          />

          {isQuickview !== 'true' && (
            <CustomBreadcrumbs
              links={[
                {
                  name: 'Home',
                  href: paths.eCommerce.allProduct,
                },
                {
                  name: productsData?.ProductName,
                },
              ]}
              sx={{ my: 5 }}
            />
          )}

          <Grid container spacing={{ xs: 5, md: 8 }} pt={isQuickview === 'true' && 5}>
            <Grid xs={12} md={6} lg={7}>
              {ProductimageData && ProductimageData.length > 0 ? (
                <EcommerceProductDetailsCarousel images={ProductimageData} />
              ) : (
                <EcommerceProductCarouselSkeleton />
              )}
            </Grid>

            <Grid xs={12} md={6} lg={5}>
              {productsData && productsData.length !== 0 ? (
                <EcommerceProductDetailsInfo
                  shopName={productsData.ShopName}
                  merchantID={productsData.MerchantID}
                  productId={productsData.ProductID}
                  promotion={
                    productsData.ProductPromotion !== '[]'
                      ? JSON.parse(productsData.ProductPromotion)
                      : []
                  }
                  variation={ProductvariationData}
                  name={productsData.ProductName}
                  price={productsData.ProductPrice}
                  rating={productsData.ProductRating}
                  review={productsData.ProductReviewCount}
                  priceSale={productsData.ProductPrice}
                  caption={productsData.ProductName}
                  numberofday={productsData.NumberOfDay}
                  product={product}
                  ProductimageData={ProductimageData}
                  isQuickview={isQuickview}
                />
              ) : (
                <EcommerceProductDetailsSkeleton />
              )}
            </Grid>
          </Grid>

          <Grid container columnSpacing={{ md: 8 }}>
            <Grid xs={12} md={6} lg={7}>
              {productsData && productsData.ProductDescription ? (
                <EcommerceProductDetailsDescription
                  description={productsData.ProductDescription}
                  specifications={ProductspecificationData}
                />
              ) : (
                <EcommerceProductDescriptionSkeleton />
              )}
            </Grid>
          </Grid>
        </Container>
      ) : (
        <LoadingScreen />
      )}

      {isQuickview !== 'true' && (
        <ReviewEcommerce
          review={ProductreviewData}
          rating={product ? product.ProductRating : 0}
          ratingData={ratingData}
        />
      )}

      {recommendedProducts && recommendedProducts.length > 0 && isQuickview !== 'true' && (
        <EcommerceProductRecommendation productsData={recommendedProducts} />
      )}

      {/* {isQuickview !== 'true' && <Footer />} */}
    </>
  );
}
