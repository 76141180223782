import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Typography, Stack, IconButton, InputAdornment, Alert, Snackbar, Button } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
// components
import Iconify from 'src/components/iconify';
import Notification from 'src/components/Notification/Notification';
import FormProvider, { RHFTextField, RHFSelect, RHFDatePicker } from 'src/components/hook-form';
import { useAuthContext } from 'src/auth/useAuthContext';
import { getUserProfile, unsubscribeUpdatePassword, updateUserProfile, updatePassword, clearUserProfileAction } from 'src/redux/slices/profile';
import { forgetPasswordOTP } from 'src/redux/slices/user';
import { useDispatch, useSelector } from 'src/redux/store';
import OTPPage from 'src/components/register_otp/otpPage';
import { Navigate, useNavigate } from 'react-router';
// routes
import { paths } from 'src/routes/paths';
import Label from 'src/components/label';
//
import { EcommerceAccountLayout } from '../layout';

// ----------------------------------------------------------------------

const GENDER_OPTIONS = ['Male', 'Female', 'Other'];
// ----------------------------------------------------------------------

EcommerceAccountPersonalView.propTypes = {
  currentUserProfile: PropTypes.object,
};

export default function EcommerceAccountPersonalView({ currentUserProfile }) {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [emailForOTP, setEmailForOTP] = useState('');
  const [passwordForSubmit, setPasswordForSubmit] = useState('');

  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { userProfileAction, passwordAction } = useSelector((state) => state.profile);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [notification, setNotification] = useState({
    open: false,
    msg: "",
    type: "success",
  });
  const vertical = 'top'
  const horizontal = 'right'

  const handleClose = () => { setNotification({ open: false, msg: "", type: "" }) }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


  const EcommerceAccountPersonalSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    emailAddress: Yup.string().required('Email address is required').email('Email address must be a valid email address'),
    phoneNumber: Yup.string('Phone number is required').matches(phoneRegExp, 'Phone number must be a valid number').min(10, "Phone number must be a valid number").max(11, "Phone number must be a valid number"),    
    birthday: Yup.string().required('Date of Birth is required'),
    gender: Yup.string().required('Gender is required'),
  });

  const defaultValues = {
    firstName: currentUserProfile?.FirstName || "",
    lastName: currentUserProfile?.LastName||"",
    emailAddress: currentUserProfile?.UserEmailAddress||"",
    phoneNumber: currentUserProfile?.UserContactNo||"",
    birthday: currentUserProfile && currentUserProfile.UserDOB ? new Date(currentUserProfile.UserDOB) : new Date(),
    gender: currentUserProfile?.UserGender||"",
    // oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(EcommerceAccountPersonalSchema),
    defaultValues,
  });

  const {
    reset,
    getValues,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors, dirtyFields },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      if(dirtyFields.birthday || dirtyFields.firstName ||dirtyFields.lastName || dirtyFields.phoneNumber || dirtyFields.gender || dirtyFields.emailAddress) {
        dispatch(updateUserProfile(user?.UserID, data.firstName, data.lastName, data.phoneNumber, moment(data.birthday).format('YYYYMMDD'), data.emailAddress, data.gender))
      }
      if (data.newPassword !== "" && data.confirmNewPassword !== "") {
        if (data.newPassword === data.confirmNewPassword) {
          setOpenForm(true);
          setEmailForOTP(data.emailAddress)
          setPasswordForSubmit(data.newPassword)
          dispatch(forgetPasswordOTP(user?.UserID, 'FORGETPASSWORD', data.emailAddress))
        } else {
          setNotification({
            open: true,
            msg: "Your password is not match, Please try again",
            type: "error",
          })
        }
    }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userProfileAction.length > 0) {
      setNotification({
        open: true,
        msg: "Profile Updated Successfully",
        type: "success",
      })
      dispatch(clearUserProfileAction());
      dispatch(getUserProfile('UserProfile', user?.UserID, 0, 999, 1, user?.UserID))
    }
  }, [dispatch, userProfileAction, user]);

  const CallOTP_Verification = (otp) => {
    try {
      dispatch(updatePassword(user?.UserID, 'PASSWORD', otp, passwordForSubmit))
    } catch (error) {
      console.error(error);
    }
  };

 
  useEffect(() => {
    if (passwordAction) {
      if (typeof passwordAction === 'string') {
        reset();
        setError('afterSubmit', {
          message: passwordAction,
        });
      } else {
      setNotification({
        open: true,
        msg: "Password Updated Successfully",
        type: "success",
      })
        dispatch(unsubscribeUpdatePassword());
        reset();
        setOpenForm(false);
      }
      dispatch(unsubscribeUpdatePassword());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, passwordAction, reset]);

  const handleResendOTP = () => {
    dispatch(forgetPasswordOTP(user?.UserID, 'FORGETPASSWORD', emailForOTP))
  }

  return (
    <EcommerceAccountLayout>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" sx={{ mb: 3 }}>
            Personal
          </Typography>
          {/* {
            user?.UserTypeID >= 16 &&
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate(paths.registerMerchant(1), { state: 0 })}
            >
              {user?.UserTypeID === 17 ? "Be A Merchant" : "View Application"}
            </Button>
          } */}
          {/* {user?.UserTypeID === 17 && <Label color='success'  >   Application for Merchant  </Label>} */}

        </Stack>

        <Box
          rowGap={2.5}
          columnGap={2}
          mb={3}
          display="grid"
          gridTemplateColumns={{ xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        >
          <RHFTextField name="firstName" label="First Name" />

          <RHFTextField name="lastName" label="Last Name" />

          <RHFTextField name="emailAddress" label="Email Address" />

          <RHFTextField name="phoneNumber" label="Phone Number" />

          {/* <Controller
            name="birthday"
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Birthday"
                slotProps={{
                  textField: {
                    helperText: error?.message,
                    error: !!error?.message,
                  },
                }}
                {...field}
                value={field.value}
              />
            )}
          /> */}


          <RHFDatePicker name="birthday" label="Birthday" />
          <RHFSelect native name="gender" label="Gender">
            {GENDER_OPTIONS.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </RHFSelect>
        </Box>

        <Stack spacing={3} sx={{ my: 5 }}>
          <Typography variant="h5"> Change Password </Typography>
          {
            getValues("confirmNewPassword") !== '' && getValues("confirmNewPassword") !== getValues("newPassword") &&<Alert severity="error">Confirm Password is not same as New Password set</Alert>
          }

          <Stack spacing={2.5}>
            <RHFTextField
              name="newPassword"
              label="New Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'carbon:view' : 'carbon:view-off'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name="confirmNewPassword"
              label="Confirm New Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'carbon:view' : 'carbon:view-off'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>

        <LoadingButton
          color="inherit"
          size="medium"
          type='submit'
          variant="contained"
          loading={isSubmitting}
        >
          Save Changes
        </LoadingButton>
      </FormProvider>


      <Notification notification={notification} setNotification={setNotification} />

      <OTPPage email={emailForOTP} CallOTP_Verification={CallOTP_Verification} onClose={() => setOpenForm(false)} open={openForm} ResendOTP={handleResendOTP}   setError={setError} errorMsg={!!errors.afterSubmit && errors.afterSubmit.message }/>
    </EcommerceAccountLayout>
  );
}
