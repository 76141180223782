import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Stack,
    Button,
    Divider,
    Typography,
    Card, Chip, IconButton,
    Unstable_Grid2 as Grid,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material';
// components
import TextMaxLine from 'src/components/text-max-line';
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField, RHFSelect } from 'src/components/hook-form';
import Notification from 'src/components/Notification/Notification';
import { addNewAddress, updateUserAddress, clearUserAddressAction } from 'src/redux/slices/profile';
import { getState, getCountry } from 'src/redux/slices/general';
import { useDispatch, useSelector } from 'src/redux/store';
import { useAuthContext } from 'src/auth/useAuthContext';
import LoadingScreen from 'src/components/loading-screen';
import { AddressNewEditForm} from '../account/address';
import  AddressItem from '../account/address/AddressItem';


// ----------------------------------------------------------------------
export default function EcommerceCheckOutChooseBIllingAddress({ onClose, currentAddress, address, onCreateBilling, orderID, selectedAddress, selectedAddressID, setSelectedAddressID, handleGetSelectedAddresID, ...other }) {
    const dispatch = useDispatch();

    const { user } = useAuthContext();

    const [notification, setNotification] = useState({
        open: false,
        msg: "",
        type: "success",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [isnewAddress, setIsNewAddress] = useState(false);

    const handleOpenNewEditForm = (action) => {
        setOpenForm(true);
        setIsNewAddress(action);
    }

    const [Selfpickup, changeSelfpickup] = useState([{
        UserAddressBookID: 0,
        UserAddressName: 'Self-pickup',
        UserAddressLine1: "Pickup Directly from our store",
        UserAddressLine2: "",
        UserCity: "",
        UserState: "",
    }])


    return (
        <>
            {openForm ?
                <AddressNewEditForm open={openForm} onClose={() => setOpenForm(false)} isNewAddress={isnewAddress} currentAddress={null} />
                :
                <Dialog fullWidth maxWidth="md" onClose={onClose} {...other}>
                    {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}> */}
                    {/* <DialogTitle sx={{ typography: 'h4', pb: 3 }}>{isNewAddress ? "Add New Address" : "Edit Address"}</DialogTitle> */}
                    <DialogTitle sx={{ typography: 'h4', pb: 3 }}>Please Select Address</DialogTitle>
                    <Button
                        onClick={onClose}
                        color="inherit"
                        startIcon={<Iconify icon="carbon:chevron-left" />}
                        sx={{ mb: 3, pl: 2, alignSelf: "flex-start" }}

                    >
                        Back to Delivery & Billing Address
                    </Button>
                    {
                        Selfpickup.map((item, index) => (
                            <DefaultAddressItem
                                key={index}
                                address={item}
                                selectedAddressID={selectedAddressID}
                                onCreateBilling={() => {
                                    setSelectedAddressID(item.UserAddressBookID)
                                    onCreateBilling(item)
                                }}
                                orderID={orderID}
                                handleGetSelectedAddresID={handleGetSelectedAddresID}
                            />
                            
                        ))
                    }
                    <DialogContent sx={{ py: 0 }}>
                        {address.map((item, index) => (
                            <AddressItem
                                key={index}
                                address={item}
                                selectedAddressID={selectedAddressID}
                                onCreateBilling={() => {
                                    setSelectedAddressID(item.UserAddressBookID)
                                    onCreateBilling(item)
                                }}
                                setIsLoading={setIsLoading}
                                // isloading={isLoading}
                                orderID={orderID}
                                viewOnly={false}
                                handleCallBack={null}
                                handleGetSelectedAddresID={handleGetSelectedAddresID}
                            />
                        ))}
                    </DialogContent>

                    <DialogActions>
                        {
                            orderID === "" &&
                            <Stack pb={1}>
                                <Button
                                    onClick={() => handleOpenNewEditForm(true)}
                                    // color="inherit"
                                    variant='contained'
                                    sx={{
                                        backgroundColor: 'primary.main',
                                        boxShadow: (theme) => theme.customShadows.z8,
                                    }}
                                    startIcon={<Iconify icon="carbon:add" color="white" />}
                                >Add New Address
                                </Button>
                            </Stack>
                        }
                    </DialogActions>
                    <Notification notification={notification} setNotification={setNotification} />

                </Dialog>}
        </>

    );
}

EcommerceCheckOutChooseBIllingAddress.propTypes = {
    onClose: PropTypes.func,
    currentAddress: PropTypes.object,
    address: PropTypes.array,
    onCreateBilling: PropTypes.func,
    orderID: PropTypes.string,
    selectedAddress: PropTypes.object,
    selectedAddressID: PropTypes.number,
    setSelectedAddressID: PropTypes.func,
    handleGetSelectedAddresID: PropTypes.func,
};

DefaultAddressItem.propTypes = {
    address: PropTypes.object,
    selectedAddressID: PropTypes.number,
    orderID: PropTypes.string,
    handleGetSelectedAddresID: PropTypes.func,
    onCreateBilling: PropTypes.func,
};

function DefaultAddressItem({ address, selectedAddressID, orderID, handleGetSelectedAddresID, onCreateBilling}) {
    const { UserAddressName, UserAddressLine1, UserAddressBookID } = address;
    return (
        <Stack
            sx={{
                p: 3,
                // mb: 3,
                textAlign: "left"
            }}
        >
            <Stack
                spacing={2}
                pb={3}
                alignItems={{
                    md: 'flex-end',
                }}
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
            >
                <Stack flexGrow={1} spacing={0}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="subtitle1">
                            {UserAddressName}
                        </Typography>
                    </Stack>

                    <Typography variant="body2">{UserAddressLine1}</Typography>
                </Stack>
                <Stack>
                    <Button variant="outlined" size="small"
                        sx={{
                            backgroundColor: Number(selectedAddressID) === Number(UserAddressBookID) && "primary.main",
                            color: Number(selectedAddressID) === Number(UserAddressBookID) && "white"
                        }}
                        disabled={orderID !== ""}
                        // onClick={onCreateBilling}
                        onClick={()=><>{handleGetSelectedAddresID(address.UserAddressBookID)}{onCreateBilling(address)}</>}
                        >

                        Self Pick Up
                    </Button>
                </Stack>

            </Stack>
            <Divider />
        </Stack>
    );
}

