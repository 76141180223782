import PropTypes from 'prop-types';
import { useState } from 'react';
import moment from 'moment';
// @mui
import {
    Stack,
    Dialog,
    Typography,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions, Box,
    TextField, InputAdornment
} from '@mui/material';

import { fCurrency } from 'src/utils/formatNumber';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// routes
import { paths } from 'src/routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
import { useSelector } from '../../../redux/store';
// ----------------------------------------------------------------------

ReloadDialog.propTypes = {
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool, 
    reloadProduct: PropTypes.object,
};

export default function ReloadDialog({ onCancel, isOpen, reloadProduct }) {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { userCheckInDate } = useSelector((state) => state.general);

    const presetValue = ["50", "100", "150", "200", "250", "Other"]
    const [selectedType, setSelectedType] = useState(null);

    const handleCancel = () => {
        setSelectedType(null)
        onCancel()
    }
    const handleSelectedType = (data, value) => {
        if (data === "Other") {
            setSelectedType({
                type: data,
                amount: 0
            })
        } else {
            setSelectedType({
                type: data,
                amount: Number(data)
            })
        }
    }

    const handleProceedToCart = () => {

        if (user && user.UserID && reloadProduct) {
            const product = reloadProduct

            const processedPromoData = [];
            const filterList = [];

            const variationArr = JSON.parse(product.ProductVariation)[0]

            const oriPrice = selectedType.amount;
            const promoPrice = selectedType.amount;
            const discount = 'false';
            const purchaseLimit = variationArr.ProductStockAmount;
            const stockLimit = variationArr.ProductStockAmount;
            const promoID = 0;
            const promoTitle = '';
            const promotionData = product.ProductPromotion !== '[]' ? product.ProductPromotion : {};

            const selectCustArr = []

            const today = new Date();
            const newdate = new Date();
            const result = newdate.setDate(today.getDate() + 3);

            processedPromoData.push({
                ...product,
                ProductPromotion: promotionData,
                MerchantShopName: product.MerchantID === 0 ? "Abba Paradise Reload" : product.ShopName,
                UserCartID: 0,
                UserID: user?.UserID,
                isHotel: 0,
                isEvent: 0,

                CheckinDate: result,
                CheckinDate113: moment(result).format('DD MMM YYYY'),
                CheckoutDate: result,
                CheckoutDate113: moment(result).format('DD MMM YYYY'),

                ProductQuantity: 1,
                ProductVariationDetailID: variationArr.ProductVariationDetailID,
                ProductImage: product.ProductImages && JSON.parse(product.ProductImages).length > 0
                    ? JSON.parse(product.ProductImages)[0].ProductMediaUrl
                    : '',
                ProductVariationValue: variationArr.ProductVariationValue,
                ProductPrice: promoPrice,
                OriProductPrice: oriPrice,
                Discount: discount,
                ProductPurchaselimit: purchaseLimit,
                ProductStockLimit: stockLimit,
                PromotionID: promoID,
                PromotionTitle: promoTitle,
                Remarks: '',
                PromoCode: '',
                PromoCodeID: 0,
                PromoCodeDiscount: 0,
                ACCESSCATEGORYID: 1,
                CartCustomization: JSON.stringify(selectCustArr),
                RelatedID: 0,
                RelatedTypeID: 0,
                ChildName: "-",
                ChildProfileImage: null,
                SchoolName: "-",
                ClassName: "-",
                isAvailable: product.ISAVAILABLE
            });

            filterList.push({
                ...processedPromoData[0],
                cartList: processedPromoData,
            });

            const obj = {
                merchantLising: filterList,
                cartLising: processedPromoData,
                orderData: null,
                isFromBookLah: false,
                isFromEmporiaApp: false,
                isReload: true,
                step: 1    
            };
            handleCancel()
            navigate(paths.eCommerce.cart, { state: obj });
        } else {
            navigate(paths.loginCover);
        }
    };


    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={() => handleCancel()}
        >
            <DialogTitle>
                <Stack textAlign="center">
                    <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        Top-up
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                        Balance: {fCurrency(user?.MembershipPoint || "0")}
                    </Typography>
                </Stack>
            </DialogTitle>

            <DialogContent sx={{ p: 5, }} >
                <Box
                    gap={{ xs: 2, md: 1, }}
                    display="grid"
                    sx={{ py: 3 }}
                    gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(3, 2fr)' }}
                >
                    {
                        presetValue.map((data) => (
                            <Stack p={1} sx={{
                                borderRadius: 1,
                                overflow: 'hidden',
                                border: (theme) => selectedType?.type === data ? `solid 1px black` : `solid 1px ${theme.palette.divider}`,
                                textAlign: "center"
                            }}
                                onClick={() => handleSelectedType(data)}
                            >
                                <Typography variant="caption" sx={{ color: selectedType?.type === data ? "black" : 'text.disabled' }}>RM</Typography>
                                <Typography variant="h5" sx={{ color: selectedType?.type === data ? "black" : 'text.disabled' }}>{data}</Typography>
                            </Stack>
                        ))
                    }
                </Box>

                {
                    selectedType && selectedType.type === "Other" &&
                    <>
                        <TextField id="basic" label=" " variant="standard" type="number" onChange={(e) => setSelectedType({
                            ...selectedType,
                            amount: Number(e.target.value)
                        })}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        RM
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Typography variant="caption" sx={{ color: (selectedType?.amount < 50 || selectedType?.amount > 1000) ? "red" : 'text.disabled' }}>Minimum is RM50 and Maximum is RM1000</Typography>
                    </>
                }
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                    onClick={() => handleProceedToCart()}
                    disabled={!(selectedType && selectedType?.amount >= 50 && selectedType?.amount <= 1000)}
                    color="inherit"
                    variant="contained"
                >
                    Confirm
                </Button>
                <Button
                    onClick={() => handleCancel()}
                    color="inherit"
                    variant="text"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}