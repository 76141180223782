import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Tab, Tabs, Stack, TextField, Typography, InputAdornment, TablePagination, Button, } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
// components
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/useAuthContext';
import { getOrdersByUserID, getOrdersStatus } from 'src/redux/slices/order';
import { useDispatch, useSelector } from 'src/redux/store';
import NoItemFound from 'src/layouts/components/NoItemFound';
// routes
import { paths } from 'src/routes/paths';
// sections
import { EcommerceAccountLayout } from 'src/sections/_e-commerce/layout';
import { clearProductCartAction, } from 'src/redux/slices/product';
import Notification from 'src/components/Notification/Notification';

import LoadingScreen from 'src/components/loading-screen';
import {
  getComparator,
  EcommerceAccountOrdersProduct,
} from '../account/orders';

// ----------------------------------------------------------------------
export const TABLE_HEAD = [
  { id: 'OrderID', label: 'Order ID' },
  { id: 'OrderName', label: 'Item' },
  { id: 'CreatedDate', label: 'Order date', width: 160 },
  { id: 'totalAmount', label: 'Price', width: 100 },
  { id: 'TrackingStatus', label: 'Status', width: 100 },
  // { id: '' },
];

// ----------------------------------------------------------------------

export default function EcommerceAccountOrdersPage() {
  const [tab, setTab] = useState('All');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { user } = useAuthContext();

  const { productCartAction } = useSelector((state) => state.product);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('LastModifiedDate');

  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [filterOrderList, setFilterOrderList] = useState('');

  const [processDataListing, setDataListing] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState(null);

  const [filterEndDate, setFilterEndDate] = useState(null);

  const { orders, orderStatus, isLoading } = useSelector((state) => state.order);

  const [notification, setNotification] = useState({
    open: false,
    msg: "",
    type: "success",
  });

  const TABS = ['All', ...orderStatus.filter((x)=> x.TrackingStatusID <3 || x.TrackingStatusID > 5).map((data) => data.TrackingStatus)]

  useEffect(() => {
    dispatch(getOrdersByUserID(0, user?.UserID))
    dispatch(getOrdersStatus());
  }, [dispatch, user]);

  const handleChangeTab = (event, newValue) => {
    setPage(0);
    setTab(newValue);
  };

  useEffect(() => {
    if (productCartAction.length > 0) {
      // dispatch(viewProductCart(user?.UserID));
      dispatch(clearProductCartAction());
      setNotification({
        open: true,
        msg: "Updated Cart",
        type: "success",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCartAction]);


  const handleListing = (tabData) => {
    let listing = []

    if (processDataListing && processDataListing.length > 0 && processDataListing.filter((x) => x.TrackingStatus === tabData).length > 0)
      listing = processDataListing.filter((x) => x.TrackingStatus === tabData)[0].ordersData
    return listing
  }

  useEffect(() => {
    if (orders && orders.length > 0) {
      const filterData = []
      const allOrderData = []

      orders.map((x) =>
        allOrderData.push({
          ...x,
          OrderProductDetail: x.OrderProductDetail && x.OrderProductDetail !== "[]" ? JSON.parse(x.OrderProductDetail) : []
        }))
      orderStatus.map((x) => {
        const orderData = []
        orders.map((y) => {
          const orderDetails = []
          if (y.OrderProductDetail && y.OrderProductDetail !== "[]") {
            JSON.parse(y.OrderProductDetail).filter((z) => z.TrackingStatus === x.TrackingStatus).map((k) => orderDetails.push(k))
          }
          if (orderDetails.length > 0)
            orderData.push({ ...y, OrderProductDetail: orderDetails })
          return {}
        })
        filterData.push({
          ...x,
          ordersData: orderData
        })
        return {}
      })
      filterData.push({ TrackingStatus: "All", TrackingStatusID: 0, ordersData: allOrderData })
      setDataListing(filterData)
    }
  }, [orders, orderStatus]);

  const handleSelectRow = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterOrderList = (event) => {
    setPage(0);
    setFilterOrderList(event.target.value);
  };

  const handleFilterStartDate = (event) => {
    setPage(0);
    setFilterStartDate(event);
  };

  const handleFilterEndDate = (event) => {
    setPage(0);
    setFilterEndDate(event);
  };

  const dataFiltered = applyFilter({
    inputData: handleListing(tab),
    comparator: getComparator(order, orderBy, 'asc'),
    filterOrderList,
    filterStartDate,
    filterEndDate,
    tab,
  });

  const isNotFound = (!dataFiltered.length && !!filterOrderList) ||
    (!!filterStartDate && !!filterEndDate) || (!isLoading && !dataFiltered.length);

  const handleResetFilter = () => {
    setFilterEndDate(null);
    setFilterStartDate(null);
    setFilterOrderList('');
    setPage(0);
  }

  if (isLoading) return <LoadingScreen />;

  return (
    <EcommerceAccountLayout>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Orders
      </Typography>

      <Tabs
        value={tab}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={handleChangeTab}
      >
        {TABS.map((category) => (
          <Tab key={category} value={category} label={`${category} (${handleListing(category).length})`} />
        ))}
      </Tabs>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 5, mb: 3 }}>
        <TextField
          fullWidth
          value={filterOrderList}
          onChange={handleFilterOrderList}
          hiddenLabel
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="carbon:search" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems="center">
          <DatePicker label="Start Date" value={filterStartDate} onChange={handleFilterStartDate} sx={{ width: 1, minWidth: 180 }} />
          <DatePicker label="End Date" value={filterEndDate} onChange={handleFilterEndDate} sx={{ width: 1, minWidth: 180 }} />
          <Button onClick={handleResetFilter}>Clear</Button>
        </Stack>
      </Stack>

      {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) =>
          row ? (
            <EcommerceAccountOrdersProduct
              key={row.OrderID}
              row={row}
              selected={selected.includes(row.OrderID)}
              onSelectRow={() => handleSelectRow(row.OrderID)}
              onClickRow={() => navigate(paths.eCommerce.account.orderDetail(row.OrderID), { state: row.OrderName })}
            />
          ) : (
            <></>
          ))}

      {isNotFound && <NoItemFound title="No orders" />}

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          page={page}
          component="div"
          count={dataFiltered.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Notification notification={notification} setNotification={setNotification} />
    </EcommerceAccountLayout>
  );
}

function applyFilter({ inputData, comparator, filterOrderList, filterStartDate, filterEndDate, tab }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterOrderList) {
    inputData = inputData.filter(
      (script) => script.OrderName?.toLowerCase().indexOf(filterOrderList.toLowerCase()) !== -1
    );
  }

  if (filterStartDate && filterEndDate) {
    inputData = inputData.filter(
      (script) =>
        moment(filterStartDate).format('DD/MM/YYYY') <= script.CreatedDate && moment(filterEndDate).format('DD/MM/YYYY') >= script.CreatedDate
    );
  }

  return (inputData);
}