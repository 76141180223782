/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // routes
import { paths } from 'src/routes/paths';
import moment from 'moment';
// @mui
import {
  Stack,
  TextField,
  IconButton,
  Typography,
  Checkbox,
  Grid,
  Autocomplete,
} from '@mui/material';
// utils
import { fCurrency } from 'src/utils/formatNumber';
import { checkAccessCategory } from 'src/utils/helpers'
// components
import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Notification from 'src/components/Notification/Notification';
import { QuantityControl } from 'src/components/hook-form';
import { ProductPricing } from '../components';
import { useAuthContext } from '../../../auth/useAuthContext';
import {
  deleteProductCart,
  updateProductCart,
  clearProductCartAction,
} from '../../../redux/slices/product';
import { useDispatch, useSelector } from '../../../redux/store';


// ----------------------------------------------------------------------

export default function EcommerceCartItemSide({
  product,
  handleSelectedItem,
  selectedProduct,
  handleAdjustSelectedQuantity,
  isTicketContain,
}) {
  const { user } = useAuthContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { productCartAction } = useSelector((state) => state.product);

  const [isChecked, setIsChecked] = useState(false);

  const [promotionData, setPromotionData] = useState({
    ProductStockAvailable: 0,
    PromotionID: '',
    DiscountPrice: '0',
    stockLimit: 0,
    purchaseLimit: 0,
    discount: 0,
    isDiscount: false,
  });

  const [notification, setNotification] = useState({
    open: false,
    msg: '',
    type: 'success',
  });

  useEffect(() => {
    if (productCartAction.length > 0) {
      dispatch(clearProductCartAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCartAction]);

  useEffect(() => {
    if (product.ProductPromotion !== null) {
      const data = JSON.parse(product.ProductPromotion).filter(
        (x) => x.ProductVariationDetailID === product.ProductVariationDetailID
      );
      if (data.length > 0 && data[0].ProductStockAvailable >= product.ProductQuantity)
        setPromotionData({
          ProductStockAvailable: data[0].ProductStockAvailable,
          PromotionID: data[0].PromotionID,
          DiscountPrice: data[0].PromotionPrice,
          stockLimit: data[0].ProductStockLimit,
          purchaseLimit: data[0].ProductPurchaselimit,
          discount: data[0].ProductDiscount,
          isDiscount: true,
        });
      if (data.length > 0 && data[0].ProductStockAvailable < product.ProductQuantity)
        setPromotionData({
          ProductStockAvailable: data[0].ProductStockAvailable,
          PromotionID: '',
          DiscountPrice: '0',
          stockLimit: 0,
          purchaseLimit: 0,
          discount: 0,
          isDiscount: false,
        });
    } else
      setPromotionData({
        ProductStockAvailable: 0,
        PromotionID: '',
        DiscountPrice: '0',
        stockLimit: 0,
        purchaseLimit: 0,
        discount: 0,
        isDiscount: false,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (
      selectedProduct.length > 0 &&
      selectedProduct.find((x) => x.UserCartID === product.UserCartID)
    ) {
      setIsChecked(true);
    } else setIsChecked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const handleDeleteCart = () => {
    dispatch(deleteProductCart(product.UserCartID, checkAccessCategory(product.isHotel, product.isEvent)));
  };

  const handleCartCheckbox = () => {
    let isDisabled = false;
    if (promotionData.PromotionID !== '' && product.ProductQuantity > promotionData.stockLimit)
      isDisabled = true;
    if (promotionData.PromotionID !== '' && product.ProductQuantity > promotionData.purchaseLimit)
      isDisabled = true;
    if (product.isProductDeleted === 1 || product.isProductVariationDeleted === 1)
      isDisabled = true;
    // if (promotionData.PromotionID !== "" && product.ProductQuantity > promotionData.ProductStockAvailable)
    //   isDisabled = true
    if (product.ProductQuantity > product.ProductStock) isDisabled = true;
    if (
      moment(product.CheckinDate113).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')
    )
      isDisabled = true;
    if (product.EventEndedInd === 1) isDisabled = true;

    return isDisabled;
  };

  const handleAddQuantity = (data) => {
    if (data < product.ProductStock) {
      handleAdjustSelectedQuantity(product.UserCartID, data + 1);
      dispatch(
        updateProductCart(
          user.UserID,
          product.UserCartID,
          data + 1,
          checkAccessCategory(product.isHotel, product.isEvent)
        )
      );
    } else setNotification({ open: true, msg: 'Maximum available stock added', type: 'error' });
  };

  const handleMinusQuantity = (data) => {
    if (data > 1) {
      handleAdjustSelectedQuantity(product.UserCartID, data - 1);
      dispatch(
        updateProductCart(
          user.UserID,
          product.UserCartID,
          data - 1,
          checkAccessCategory(product.isHotel, product.isEvent)
        )
      );
    } else setNotification({ open: true, msg: 'Minimum 1 item is required', type: 'error' });
  };

  return (
    <Stack
      alignItems="center"
      sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
      py={1}
    >
      <Grid container pty={1} alignItems="center" sx={{ opacity: handleCartCheckbox() ? 0.5 : 1 }}>
        <Grid item xs={2}>
          <Checkbox
            checked={isChecked}
            sx={{ color: handleCartCheckbox() && 'lightgrey' }}
            disabled={handleCartCheckbox()}
            onClick={() => handleSelectedItem(product, '')}
          />
        </Grid>
        <Grid item xs={3}>
          <Image
            src={product.ProductImage}
            sx={{
              width: { xs: 60, sm: 80 },
              height: { xs: 60, sm: 80 },
              flexShrink: 0,
              borderRadius: 1.5,
              bgcolor: 'background.neutral',
            }}
          />
        </Grid>

        <Grid item xs={7}>
          <Stack spacing={0.5} sx={{ pl: 1 }}>
            <Typography
              variant="subtitle2"
              onClick={() =>
                product.isHotel === 1
                  ? navigate(paths.hotel.list(product.ProductID))
                  : product.isEvent === 1
                    ? navigate(
                      paths.booklahEvent.event(product.ProductName.replace(/ /g, '').toLowerCase())
                    )
                    : navigate(paths.eCommerce.product(product.ProductID))
              }
              noWrap
            >
              {console.log("DSdasdsadasdsa", product)}
              {product.ProductName}
            </Typography>
            {/* {product.isHotel === 1 ? (
              <> */}
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {' '}
              {product.ProductVariationValue}{' '}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '12px' }}>
              {' '}
              {product.CheckinDate113} - {product.PropertyTypeID === 5 ? product.CheckoutHallDate113 : product.CheckoutDate113}{' '}
            </Typography>
            {moment(product.CheckinDate113).format('YYYY-MM-DD') <
              moment(new Date()).format('YYYY-MM-DD') && (
                <Stack direction="row">
                  <Iconify icon="ph:warning-fill" width={18} sx={{ mr: 1, color: 'red' }} />
                  <Typography variant="body2" sx={{ color: 'red', fontSize: '12px' }}>
                    Exceed Current Date
                  </Typography>
                </Stack>
              )}
            {/* </>
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {' '}
                Variation: {product.ProductVariationValue}{' '}
              </Typography>
            )} */}

            {promotionData.PromotionID !== '' && (
              <Label color="error" sx={{ ml: 2, mt: 0.5 }}>
                {' '}
                {promotionData.discount.toFixed(2)} % OFF{' '}
              </Label>
            )}

            {promotionData.PromotionID !== '' ? (
              <ProductPricing
                price={Number(promotionData.DiscountPrice)}
                priceSale={Number(product.ProductPrice)}
                discount={promotionData.discount}
                isDiscount={promotionData.isDiscount}
                row="false"
              />
            ) : product.EventMerchandiseInd === 1 && isTicketContain ? (
              <ProductPricing
                price={Number(
                  product.DiscountedProductPrice ? product.DiscountedProductPrice : '0'
                )}
                priceSale={Number(product.ProductPrice ? product.ProductPrice : '0')}
                discount={10}
                isDiscount
                percent={false}
                sx={{ typography: 'subtitle2' }}
              />
            ) : (
              <Stack sx={{ typography: 'subtitle2' }}>
                {' '}
                {fCurrency(product.ProductPrice ? product.ProductPrice : '0')}{' '}
              </Stack>
            )}

            <Stack direction="row" justifyContent="space-between">
              {/* <Autocomplete
                disablePortal
                id="cartQuantity"
                options={QuantitySelection}
                getOptionLabel={(option) => String(option)}
                value={product.ProductQuantity}
                onChange={handleAmountChange}
                sx={{ width: 200 }}
                size='small'
                renderInput={(params) => <TextField {...params} size='small' label="Quantity" />}
              /> */}

              <QuantityControl
                value={product.ProductQuantity}
                onAdd={(e) => handleAddQuantity(e)}
                onMinus={(e) => handleMinusQuantity(e)}
                mt={-15}
                mb={0}
              />

              <IconButton onClick={handleDeleteCart}>
                <Iconify icon="carbon:trash-can" color="red" />
              </IconButton>
            </Stack>

            {promotionData.PromotionID !== '' ? (
              <Stack sx={{ typography: 'subtitle2', color: 'red' }}>
                Total:{' '}
                {fCurrency(
                  promotionData.DiscountPrice
                    ? promotionData.DiscountPrice * product.ProductQuantity
                    : '0'
                )}
              </Stack>
            ) : product.EventMerchandiseInd === 1 && isTicketContain ? (
              <Stack sx={{ typography: 'subtitle2' }}>
                Total:{' '}
                {fCurrency(
                  product.DiscountedProductPrice
                    ? product.DiscountedProductPrice * product.ProductQuantity
                    : '0'
                )}
              </Stack>
            ) : (
              <Stack sx={{ typography: 'subtitle2' }}>
                Total:{' '}
                {fCurrency(
                  product.ProductPrice ? product.ProductPrice * product.ProductQuantity : '0'
                )}
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>

      {product.EventMerchandiseInd === 1 && isTicketContain && (
        <Typography variant="caption" color="#d53600">
          **This price only apply with purchase of event ticket
        </Typography>
      )}

      {product.EventEndedInd === 1 && (
        <Typography variant="caption" color="#d53600">
          **Event ticket sold out
        </Typography>
      )}

      {/* {product.EventMerchandiseInd === 1 && !isTicketContain && (
        <Typography variant='caption' color="#d53600">**This is a promotion with purchase of event ticket</Typography>
      )} */}

      {(product.isProductDeleted === 1 || product.isProductVariationDeleted === 1) && (
        <Typography variant="caption" color="red">
          This product is currently not available
        </Typography>
      )}

      {promotionData.PromotionID !== '' &&
        product.ProductQuantity > promotionData.purchaseLimit && (
          <Typography variant="caption" color="red">
            This promotion allow only {promotionData.purchaseLimit} item per transaction
          </Typography>
        )}

      {promotionData.PromotionID !== '' &&
        product.ProductQuantity === promotionData.ProductStockAvailable && (
          <Typography variant="caption" color="red">
            {promotionData.ProductStockAvailable} item left for this promotion, more then item left
            will calculate in non-discount price{' '}
          </Typography>
        )}

      {promotionData.PromotionID !== '' && product.ProductQuantity > promotionData.stockLimit && (
        <Typography variant="caption" color="red">
          Only {promotionData.stockLimit} Promotion item left{' '}
        </Typography>
      )}

      {product.ProductQuantity > product.ProductStock && (
        <Typography variant="caption" color="red">
          Insufficient stock for you purchase
        </Typography>
      )}

      <Notification notification={notification} setNotification={setNotification} />
    </Stack>
  );
}

EcommerceCartItemSide.propTypes = {
  product: PropTypes.shape({
    EventEndedInd: PropTypes.number,
    DiscountedProductPrice: PropTypes.number,
    EventMerchandiseInd: PropTypes.number,
    ProductImage: PropTypes.string,
    ProductName: PropTypes.string,
    ProductID: PropTypes.number,
    ProductPrice: PropTypes.number,
    ProductPromotion: PropTypes.string,
    ProductVariationValue: PropTypes.string,
    ProductStock: PropTypes.number,
    ProductQuantity: PropTypes.number,
    MerchantShopName: PropTypes.string,
    MerchantID: PropTypes.number,
    UserCartID: PropTypes.number,
    isHotel: PropTypes.number,
    isEvent: PropTypes.number,
    CheckinDate113: PropTypes.string,
    CheckoutDate113: PropTypes.string,
    CheckoutHallDate113: PropTypes.string,
    PropertyTypeID: PropTypes.number,
    ProductVariationDetailID: PropTypes.number,
    isProductDeleted: PropTypes.number,
    isProductVariationDeleted: PropTypes.number,
  }),
  handleSelectedItem: PropTypes.func,
  handleAdjustSelectedQuantity: PropTypes.func,
  selectedProduct: PropTypes.array,
  isTicketContain: PropTypes.bool,
};
